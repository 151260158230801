import { AppRegistrationOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useState } from 'react'

import ReportApi from '@/apis/beta/Report/ReportApi'
import NumericFormatCustom from '@components/NumericFormatCustom'
import FormCustomized from '@components/shared/FormCustomized'
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import _ from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const MIN = 2013

const CreateModal = ({ onClose = () => { } }) => {

    const queryClient = useQueryClient()

    const { mutateAsync, isLoading } = useMutation(ReportApi.CreateBusinessPerformance)

    const methods = useForm({
        defaultValues: {
            year: 2024,
            month: 1,
            revenue: 0,
            cost: 0,
            profit: 0, // LN gộp
            operating_expenses: 0, // Chi Phí Hoạt Động - CPHD
            operating_profit: 0, // Lợi Nhuận Từ HDKD = LN gộp - CPHD
            other_revenue: 0, // Thu Nhập Khác
            other_expenses: 0, //Chi Phí Khác
            earning_before_tax: 0, // LN trước thuế (EBT) = Lợi Nhuận Từ HDKD + Thu Nhập Khác - Chi phí khác,
            corporate_incomes_tax: 0, // Thuế TNDN
            earning_after_tax: 0// EBT - Thuế TNDN
        }
    })


    const BUSINESS_PERFORMANCE_FIELDS = [
        {
            name: 'year', component: "SingleSelect", cols: { xs: 12, md: 8, xl: 8 },
            params: {
                options: [...Array.from({ length: 100 }).map((__, i) => i + MIN)?.map((value) => ({ value, label: value }))],
                input: { label: 'Năm khởi tạo dữ liệu *', placeholder: 'Chọn năm...' }
            }
        }, {
            name: 'month', component: "SingleSelect", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                options: [...Array.from({ length: 12 }).map((__, i) => i + 1)?.map((value) => ({ value, label: value }))],
                input: { label: 'Tháng khởi tạo dữ liệu *', placeholder: 'Chọn Tháng...' }
            },
        }, {
            name: 'revenue', component: "TextField", cols: { xs: 12, md: 12, xl: 12 },
            params: {
                label: 'Doanh Thu', placeholder: 'Hệ thống sẽ tự động tính theo hoá đơn trên DC...',
                InputProps: { inputComponent: NumericFormatCustom },
                trigger: () => { methods.setValue('profit', methods.watch(`revenue`) - methods.watch(`cost`)) }
            }
        }, {
            name: 'cost', component: "TextField", cols: { xs: 12, md: 8, xl: 8 },
            params: {
                label: 'Giá Vốn *', placeholder: 'Nhập giá vốn...', InputProps: { inputComponent: NumericFormatCustom }
            },
            trigger: () => { methods.setValue('profit', methods.watch(`revenue`) - methods.watch(`cost`)) }
        }, {
            name: 'profit', component: "TextField", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                disabled: true,
                label: 'Lợi Nhuận Gộp', InputProps: { inputComponent: NumericFormatCustom },
                helperText: 'Lợi nhuận gộp = Doanh thu - Giá vốn'
            }
        }, {
            name: 'operating_expenses', component: "TextField", cols: { xs: 12, md: 8, xl: 8 },
            params: {
                label: 'Chi Phí Hoạt Động Kinh Doanh *', InputProps: { inputComponent: NumericFormatCustom },
            },
            trigger: () => { methods.setValue('operating_profit', methods.watch(`profit`) - methods.watch(`operating_expenses`)) }
        }, {
            name: 'operating_profit', component: "TextField", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                disabled: true,
                label: 'Lợi Nhuận Từ HĐKD', InputProps: { inputComponent: NumericFormatCustom },
                helperText: 'Lợi nhuận từ HĐKD = Lợi nhuận gộp - Chi phí HĐKD'
            }
        }, {
            name: 'other_revenue', component: "TextField", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                label: 'Thu Nhập Khác', InputProps: { inputComponent: NumericFormatCustom },
            },
            trigger: () => {
                methods.setValue('earning_before_tax',
                    parseFloat(methods.watch(`operating_profit`))
                    - parseFloat(methods.watch(`other_expenses`))
                    + parseFloat(methods.watch(`other_revenue`))
                )
            }
        }, {
            name: 'other_expenses', component: "TextField", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                label: 'Chi Phí Khác', InputProps: { inputComponent: NumericFormatCustom },
            },
            trigger: () => {
                methods.setValue('earning_before_tax',
                    parseFloat(methods.watch(`operating_profit`))
                    - parseFloat(methods.watch(`other_expenses`))
                    + parseFloat(methods.watch(`other_revenue`))
                )
            }
        }, {
            name: 'earning_before_tax', component: "TextField", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                disabled: true,
                label: 'LN Trước Thuế', InputProps: { inputComponent: NumericFormatCustom },
            },

        }, {
            name: 'corporate_incomes_tax', component: "TextField", cols: { xs: 12, md: 8, xl: 8 },
            params: {
                label: 'Thuế TNDN', InputProps: { inputComponent: NumericFormatCustom },
            },
            trigger: () => { methods.setValue('earning_after_tax', methods.watch(`earning_before_tax`) - methods.watch(`corporate_incomes_tax`)) }
        }, {
            name: 'earning_after_tax', component: "TextField", cols: { xs: 12, md: 4, xl: 4 },
            params: {
                disabled: true,
                label: 'Lợi Nhuận Sau Thuế', InputProps: { inputComponent: NumericFormatCustom },
            }
        },
    ]

    const onSubmit = (values) => {
        const response = mutateAsync({
            data: [{
                ..._.omit(values, [
                    'revenue',
                ])
            }]
        })
        console.log(`response`, response)
        //

        queryClient.refetchQueries(['reports.finance.business.performance'])

        //
        onClose()
    }
    return (
        <FormProvider {...methods}>
            <Dialog open={true} component="form" fullWidth maxWidth="md" onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogTitle>
                    Thêm Dữ Liệu
                </DialogTitle>
                <DialogContent sx={{}}>
                    <Box sx={{ mt: 2 }}>
                        <FormCustomized attributes={BUSINESS_PERFORMANCE_FIELDS} />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} size='small' variant='outlined' onClick={onClose}>Huỷ</Button>
                    <Button disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={14} sx={{ bgcolor: "#777680" }} />}
                        size='small' variant='contained' type="submit">Thêm Dữ Liệu</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    )
}

const Create = () => {
    const [modal, setModal] = useState({ open: false, data: undefined })
    return (
        <>
            {modal?.open && <CreateModal onClose={() => setModal({ open: false, data: undefined })} />}
            <Button size='small' startIcon={<AppRegistrationOutlined fontSize='small' />} variant='outlined'
                onClick={() => setModal({ open: true, data: undefined })}
            >
                Thêm Dữ Liệu
            </Button>
        </>
    )
}
export default Create
// export default withPermission(Create, { feature: 'finance', action: 'business-performance-create', type: 'Button' })