import { payloadToQueries, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class ProfitabilityApi {
    Create = async ({ data = {} }) => {
        await requestWithToken(
            `${API_V3_URL}/reports/finance/profitabilities`,
            "POST",
            {
                profitability: data
            },
        ).then(({ data }) => {
            return data;
        }).catch((err) => {
            return err
        })
    };

    List = ({ query }) => {
        const queries = payloadToQueries(query);
        return useQueryWithCustomized(
            ["reports.finance.profitabilities.sheets", queries],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/reports/finance/profitabilities?${queries}`
                )
                    .then(({ data }) => {
                        if (data?.errors) return data;

                        return data?.data?.profitabilities || []
                    })
                    .catch((error) => {
                        return []
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    };

    Export = async ({ query }) => {
        const queries = payloadToQueries({ ...query, response_format: '' });
        await requestWithToken(
            `${API_V3_URL}/reports/finance/profitabilities?${queries}`,
            "GET",
        ).then(({ data }) => {
            return data;
        }).catch((err) => {
            return err
        })
    };
}

export default ProfitabilityApi = new ProfitabilityApi();
