import BalanceSheetApi from '@/apis/beta/Report/BalanceSheetApi'
import NumericFormatCustom from '@components/NumericFormatCustom'
import FormCustomized from '@components/shared/FormCustomized'
import { useNotificationContext } from '@contexts/notification'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const Create = () => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const [created, setCreated] = useState(false)
    const methods = useForm({
        defaultValues: {}
    })
    const { mutateAsync, isLoading } = useMutation(BalanceSheetApi.Create)

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            data: values
        })

        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message || 'Failed', severity: 'error' })
        }
        queryClient.refetchQueries('reports.finance.balance.sheets')

        return setNotification({ open: true, message: 'Tạo thành công', severity: 'success' })
    }

    const BALANCE_SHEET_FIELDS = [
        {
            name: 'period', component: "DateField", cols: { xs: 12, md: 6, xl: 6 },
            params: {
                label: 'Thời gian báo cáo', placeholder: 'Chọn thời gian báo cáo'
            }
        }, {
            name: 'report_type', component: "SingleSelect", cols: { xs: 12, md: 6, xl: 6 },
            params: {
                input: { label: 'Loại báo cáo', placeholder: 'Chọn loại báo cáo' }
            }
        }, {
            name: 'assets', component: 'ArrayField', cols: { xs: 12, md: 12, xl: 12 },
            params: {
                styled: {
                    padding: 0,
                    border: 'none',
                },
                label: 'Quản Lý Tài Sản',
                name: 'assets',
                attributes: [
                    {
                        name: 'asset_type', component: 'TextField', cols: { xs: 6, md: 6, xl: 6 },
                        params: { label: 'Loại Tài Sản', placeholder: 'Chọn loại tài sản' }
                    },
                    {
                        name: 'asset_value', component: 'TextField', cols: { xs: 5, md: 4.5, xl: 4.5 },
                        params: {
                            label: 'Giá Trị Tài Sản', placeholder: 'Nhập giá trị tài sản...',
                            InputProps: { inputComponent: NumericFormatCustom }
                        }
                    },
                ]
            }
        }, {
            name: 'liabilities', component: 'ArrayField', cols: { xs: 12, md: 12, xl: 12 },
            params: {
                styled: {
                    padding: 0,
                    border: 'none',
                },
                label: 'Quản Lý Tổng Nợ',
                name: 'liabilities',
                attributes: [
                    {
                        name: 'liability_type', component: 'TextField', cols: { xs: 6, md: 6, xl: 6 },
                        params: { label: 'Loại Nợ', placeholder: 'Chọn loại nợ' }
                    },
                    {
                        name: 'liability_value', component: 'TextField', cols: { xs: 5, md: 4.5, xl: 4.5 },
                        params: {
                            label: 'Giá Trị Nợ', placeholder: 'Nhập giá trị nợ...',
                            InputProps: { inputComponent: NumericFormatCustom }
                        }
                    },
                ]
            }
        }, {
            name: 'equities', component: 'ArrayField', cols: { xs: 12, md: 12, xl: 12 },
            params: {
                styled: {
                    padding: 0,
                    border: 'none',
                },
                label: 'Nguồn Vốn Chủ Sở Hữu',
                name: 'equities',
                attributes: [
                    {
                        name: 'equity_type', component: 'TextField', cols: { xs: 6, md: 6, xl: 6 },
                        params: { label: 'Loại VCSH', placeholder: 'Chọn VCSH' }
                    },
                    {
                        name: 'equity_value', component: 'TextField', cols: { xs: 5, md: 4.5, xl: 4.5 },
                        params: {
                            label: 'Giá Trị VCSH', placeholder: 'Nhập giá trị VCSH...',
                            InputProps: { inputComponent: NumericFormatCustom }
                        }
                    },
                ]
            }
        }
    ]
    return (
        <>
            <Button size='small' variant='outlined' startIcon={<AddOutlined />}
                onClick={() => setCreated(true)}
                disabled={created}
            >
                Tạo Báo Cáo
            </Button>
            <FormProvider {...methods}>
                <Dialog open={created} fullWidth maxWidth="md" component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle>
                        Tạo Báo Cáo Cân Đối Kế Toán
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <FormCustomized attributes={BALANCE_SHEET_FIELDS} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button size='small'
                            disabled={isLoading}
                            variant='outlined'
                            onClick={() => setCreated(false)}
                        >Huỷ Thao Tác</Button>
                        <Button size='small'
                            disabled={isLoading}
                            startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                            variant='contained' type="submit">Tạo Báo Cáo</Button>
                    </DialogActions>
                </Dialog>
            </FormProvider>
        </>

    )
}

export default Create