import { Box } from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";
import CancelInvoice from "./CancelInvoice";
import PaymentTransaction from "./PaymentTransaction";
import PreviewInvoice from "./PreviewInvoice";
import RefundInvoice from "./RefundInvoice";
import PreviewStatement from "./PreviewStatement";
import SendEmail from "./SendEmail";
import SendStatement from "./SendStatement";
import Destroy from "./Destroy";
import CreateNote from "./CreateNote";
import Approved from "./Approved";
import InvoiceExport from "./Export";


const Modules = {
  PaymentTransaction: PaymentTransaction,
  SendEmail: SendEmail,
  SendStatement: SendStatement,
  RefundInvoice: RefundInvoice,
  CancelInvoice: CancelInvoice,
  PreviewInvoice: PreviewInvoice,
  PreviewStatement: PreviewStatement,
  DestroyInvoice: Destroy,
  CreateNote: CreateNote,
  Approved: Approved,
  Export: InvoiceExport,
};

const Buttons = ({ packages = [], invoice }) => {
  const queryClient = useQueryClient();

  const [isdisabled, setDisabled] = React.useState(
    invoice?.invoice_status !== 1
  );
  React.useEffect(() => {
    setDisabled(invoice?.invoice_status !== 1);
  }, [invoice]);

  const handleCallback = () => {
    let debug = "";
    console.log(
      `debug.invoices:refetchQueries.invoice_id:${invoice?.invoice_id}.task:started`
    );
    const refetchQueries = [
      "invoice.transactions.list",
      "invoices.list",
      "invoices.statistic",
      "invoice.detail",
      "invoice.informations",
      "invoice.mailers.list",
      "invoice.loggers",
      "invoice.services.list",
      "invoice.invoice_items",
      "invoice.quickview",
      "invoice.preview-mailer",
      "customer.invoices", // list invoice
      "customers-invoices.statistics", // report customer invoice
      "customer.serivce-credits", // list credits
      "customer.service-credit", //report of credits
    ];
    refetchQueries?.forEach((key) => {
      debug += `refetchQueriesKey:${key}.`;
      try {
        queryClient.refetchQueries([`${key}`]);
        debug += "status:completed.";
      } catch (error) {
        debug += `status:failed.message:${error?.message}`;
      }
    });
    console.log(debug);
    console.log(
      `debug.invoices:refetchQueries.invoice_id:${invoice?.invoice_id}.task:completed`
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minWidth: { xs: "100%", md: 250 },
        gap: { xs: 1.5, md: 2 },
      }}
    >
      {packages?.map((item, index) => {
        const Button = Modules[item];
        if (!Button) return null;
        return (
          <Button
            key={index}
            {...invoice}
            callback={handleCallback}
            disabled={isdisabled}
            setDisabled={setDisabled}
          />
        );
      })}
    </Box>
  );
};

export default Buttons;
