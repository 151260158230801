import ReportSummary from "./Summary";
import ReportSale from "./Sale/Team";
import ReportMarketing from "./Marketing";
import ReportWarehouse from "./Warehouse";
import ReportService from "./Service";
import ReportIncome from "./Income";
import Invoice from "./Invoice";
import Finance from "./Finance";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ReportSummary,
  ReportSale,
  ReportMarketing,
  ReportWarehouse,
  ReportService,
  ReportIncome,
  Invoice,
  Finance
};
