import ReportApi from "@/apis/beta/Report/ReportApi";
import { useNotificationContext } from "@contexts/notification";
import { UploadFileOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useRef } from "react";
import { useMutation } from "react-query";

const Import = () => {
    const inputFileRef = useRef();
    const { mutateAsync, isLoading } = useMutation(ReportApi.Import);
    const { setNotification } = useNotificationContext();

    const handleImport = () => {
        const input = inputFileRef?.current;
        if (!input) return;

        input.click();
        input.onchange = async (e) => {
            const file = e?.target?.files?.[0];
            if (!file) return;
            const response = await mutateAsync(file);
            if (response?.errors)
                return setNotification({
                    open: true,
                    message: response?.errors?.[0]?.message || "Something went wrong",
                    severity: "error",
                });

            setNotification({
                open: true,
                message: "Đồng bộ thành công",
                severity: "success",
            });
            return (input.value = "");
        };
    };

    return (
        <Button
            variant="outlined"
            size="small"
            onClick={handleImport}
            startIcon={
                isLoading ? (
                    <CircularProgress size={14} sx={{ color: "#777680" }} />
                ) : (
                    <UploadFileOutlined fontSize="small" />
                )
            }
            disabled={isLoading}
        >
            <input type="file" hidden ref={inputFileRef} />
            Import Dữ Liệu
        </Button>
    );
};

export default Import
