import { format_date_short, format_numeral_price } from '@/functions'
import EChart, { SCHEMES } from '@components/EChart'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { useMemo } from 'react'

const StackLineChart = ({ data = {
    industries: [],
    services: [],
    invoices: []
}, state = { gte: null, lte: null } }) => {

    const buildEchartdatas = useMemo(() => {
        return data?.services?.map((name, index) => {

            const total = _.sumBy(data?.invoices?.filter(invoice => invoice?.service_group_code === name), (invoice) => invoice?.total)

            return {
                name: name,
                value: total,
                itemStyle: { color: SCHEMES?.material[index] }
            }

        })
    }, [data])

    const options = {
        title: [
            {
                text: `Tổng Doanh Thu: ${format_numeral_price(data?.invoices.reduce((total, invoice) => {
                    return total + invoice?.total
                }, 0))}`,
                subtext: `Báo cáo doanh thu theo nhóm dịch vụ từ ${format_date_short(state?.gte?.$d)} - ${format_date_short(state?.lte?.$d)}`,
                left: 'center',
                top: 'top'
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                const formattedValue = format_numeral_price(params.value)
                return `${params.name}: ${formattedValue} (${params?.percent}%)`
            }
        },
        legend: {
            left: 'center',
            top: 'bottom',
        },
        series: [
            {
                name: 'Doanh Thu',
                type: 'pie',
                radius: '70%',
                data: buildEchartdatas,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    const totalPrice = useMemo(() => {
        return _.sumBy(data?.invoices, (invoice) => invoice?.total)
    }, [data?.invoices])
    return (
        <Grid container>
            <Grid item xs={12} md={3}>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: 'medium', }}>
                            Số Liệu Thống Kê Theo Dịch Vụ
                        </Typography>
                        <Typography sx={{ fontSize: 14, mb: 1.25 }}>
                            Tổng Doanh Thu: {format_numeral_price(totalPrice)}
                        </Typography>
                        {data?.services?.map((service, index) => {
                            const totalAmount = _.sumBy(data?.invoices?.filter(invoice => invoice?.service_group_code === service), (invoice) => invoice?.total)
                            return (
                                <Typography key={index} sx={{ display: 'flex', pb: 1.25, fontSize: 13 }} >
                                    <Typography sx={{ display: 'inline-flex', fontSize: 13, fontWeight: 'medium', minWidth: 160 }}>
                                        {!!service ? (service) : 'Khác'}
                                    </Typography>
                                    <Typography component="span" sx={{ fontSize: 13 }} color="text.grey">:{" "}</Typography>
                                    <Typography sx={{ fontSize: 13, width: 120, float: 'right', textAlign: 'right' }}>
                                        {format_numeral_price(totalAmount)}
                                    </Typography>

                                    <Typography sx={{ fontSize: 13, width: 120, float: 'right', textAlign: 'right' }} color="text.grey">
                                        Tỉ trọng: {format_numeral_price(Math.round((totalAmount * 10000 / totalPrice)) / 100, true)}%
                                    </Typography>
                                </Typography>
                            )
                        })}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={9}>
                <div style={{ height: '700px' }}>
                    <EChart options={options} />
                </div>
            </Grid>
        </Grid>
    )
}

export default StackLineChart