import PopperAction from "@components/PopperAction";
import {
	MoreVertOutlined
} from "@mui/icons-material";
import DevicesIcon from '@mui/icons-material/Devices';
import { Box, IconButton, Popover, Typography } from "@mui/material";
import React, { Fragment } from "react";
import ServiceInformation from "./ServiceInformation";
import ServicePricingAmount from "./ServicePricingAmount";
const CloudServerPreview = ({ data = {}, remover, insert, isPoc = false }) => {

	const [anchorEl, setAnchorEl] = React.useState(null);

	const [INITIAL_ACTIONS] = React.useState([
		{
			order: 1,
			name: "Add",
			icon: "Add",
			label: "Duplicated",
			handleClick: (newProduct) => {
				try {
					insert({ ...newProduct });
				} catch (error) {
					console.log(error);
				}
				return onClose();
			},
		},
		{
			order: 2,
			name: "delete",
			icon: "Delete",
			label: "Xoá",
			handleClick: () => {
				try {
					remover();
				} catch (error) {
					console.log(error);
				}
				return onClose();
			},
		},
	]);

	const onClose = () => setAnchorEl(undefined);

	return (
		<Fragment>
			{!!Boolean(anchorEl) && (
				<Popover anchorEl={anchorEl} open={!!Boolean(anchorEl)}>
					<PopperAction
						actions={INITIAL_ACTIONS}
						data={data}
						handleClose={onClose}
					/>
				</Popover>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column-reverse", md: "row" },
					gap: 1,
					justifyContent: "space-between",
					border: "1px solid rgba(0, 0, 0, 0.18)",
					p: 2,
				}}
			>
				<Box sx={{ width: "100%" }}>
					<Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5, gap: 1 }}>
						<DevicesIcon fontSize="small" sx={{ m: 'auto 0' }} />
						<Typography
							component="div"
							sx={{
								fontSize: 14,
								fontWeight: "medium",
							}}
						>
							{data?.product_group_name} - {data?.product_name}
						</Typography>
					</Box>

					<ServiceInformation
						serviceInformations={data?.product_informations}
					/>
					{!isPoc && <ServicePricingAmount {...data} />}
				</Box>
				<IconButton
					size="small"
					sx={{
						m: "0 0 auto auto",
						bgcolor: "rgba(0, 0, 0, 0.08)",
					}}
					disabled={!!Boolean(anchorEl)}
					onClick={(event) => setAnchorEl(event?.currentTarget)}
				>
					<MoreVertOutlined fontSize="small" />
				</IconButton>
			</Box>
		</Fragment>
	);
};

export default CloudServerPreview;
