import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi'
import withPermission from '@/hocs/withPermission'
import { useNotificationContext } from '@contexts/notification'
import { CheckOutlined } from '@mui/icons-material'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
const InvoiceExport = ({
    disabled = true,
    setDisabled,
    callback,
    ...invoice
}) => {
    const { setNotification } = useNotificationContext()

    const [invoiceNumber, setInvoiceNumber] = useState('')
    const { mutateAsync, isLoading } = useMutation(InvoiceApi.Export)
    const [openModal, setOpenModal] = React.useState(false)
    const onSubmit = async () => {
        const exported = await mutateAsync({
            invoiceId: invoice?.invoice_id,
            invoiceNumber
        })
        if (!exported || exported?.errors) {
            return setNotification({
                open: true,
                message: exported?.errors?.[0]?.message || "Xác nhận xuất hoá đơn thất bại. Vui lòng thử lại!",
                severity: 'error'
            })
        }

        try {
            !!callback && callback()
            handlerClick()
        } catch (error) {
            throw error?.message
        }

        return setNotification({
            open: true,
            message: "Xác nhận xuất hoá đơn thành công",
            severity: 'success'
        })
    }

    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }
    return (
        <React.Fragment>
            {openModal && <Dialog open={true}>
                <DialogTitle>
                    Xác Nhận Xuất Bill
                </DialogTitle>
                <DialogContent>
                    <Typography component="div">
                        Xác nhận bill đã được xuất hoá đơn trên hệ thống MISA.
                        Thao tác sẽ không thể phục hồi, bạn có muốn thực hiện?
                    </Typography>
                    <FormControl fullWidth>
                        <TextField size='small' placeholder='Nhập số hoá đơn tương ứng trên MISA'
                            label="Số hoá đơn trên MISA"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={invoiceNumber}
                            onChange={(e) => setInvoiceNumber(e?.target?.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='outlined'
                        disabled={isLoading}
                        onClick={handlerClick}
                    >Huỷ Thao Tác</Button>
                    <Button size='small' variant='contained'
                        disabled={isLoading}
                        startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                        onClick={onSubmit}>
                        Xác Nhận
                    </Button>
                </DialogActions>
            </Dialog>}
            <Button size='small'
                variant='contained'
                fullWidth
                disabled={disabled || !!invoice?.cskh_confirmed || !!invoice?.finance_confirmed || true}
                onClick={handlerClick}
                startIcon={<CheckOutlined />}
            >
                Đã Xuất Hoá Đơn Trên Misa
            </Button>
        </React.Fragment>
    )
}
export default withPermission(InvoiceExport, { feature: "invoice", action: "update", type: "button" })

// export default withPermission(InvoiceExport, { feature: "invoice", action: "export", type: "button" })