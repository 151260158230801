import { CellPricing } from '@components/DataGrid/Cell';
import Box from '@mui/material/Box';
import {
    DataGridPro
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import * as React from 'react';


const sumary = ({ payload = [] }) => {

    return {
        revenue: payload.reduce((total, item) => total + item.revenue, 0),
        cost: payload.reduce((total, item) => total + item.cost, 0),
        profit: payload.reduce((total, item) => total + item.profit, 0),
        operating_expenses: payload.reduce((total, item) => total + item.operating_expenses, 0),
        operating_profit: payload.reduce((total, item) => total + item.operating_profit, 0),
        other_revenue: payload.reduce((total, item) => total + item.other_revenue, 0),
        other_expenses: payload.reduce((total, item) => total + item.other_expenses, 0),
        earning_before_tax: payload.reduce((total, item) => total + item.earning_before_tax, 0),
        corporate_incomes_tax: payload.reduce((total, item) => total + item.corporate_incomes_tax, 0),
        earning_after_tax: payload.reduce((total, item) => total + item.earning_after_tax, 0)
    }
}


const columns = [
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'revenue', headerName: 'Doanh Thu Thuần', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'cost', headerName: 'Giá Vốn', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'profit', headerName: 'Lợi Nhuận Gộp', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'operating_expenses', headerName: 'Chi Phí Hoạt Động', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'operating_profit', headerName: 'Lợi Nhuận Từ HDKD (LN Thuần)', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'other_revenue', headerName: 'Thu Nhập Khác', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'other_expenses', headerName: 'Chi Phí Khác', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'earning_before_tax', headerName: 'Lợi Nhuận Trước Thuế (EBT)', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'corporate_incomes_tax', headerName: 'Thuế Thu Nhập Doanh Nghiệp', },
    { renderCell: ({ value }) => <CellPricing price={value} />, minWidth: 150, field: 'earning_after_tax', headerName: 'Lợi Nhuận Sau Thuế (Lợi Nhuận Ròng)', },

];

const getTreeDataPath = (row) => row.hierarchy;

export default function TreeDataDisableChildrenSorting({ datasheets = [] }) {
    const [sortModel, setSortModel] = React.useState([
        { field: 'recruitmentDate', sort: 'asc' },
    ]);


    const buildDataSheets = React.useMemo(() => {
        let rows = []

        const yearUniqs = _.groupBy(datasheets, (row) => {
            return [row?.year]
        })

        _.each(yearUniqs, (months, year) => {
            let hierarchy = [`Năm ${year}`]
            const quarterlyUniqs = _.groupBy(months, (child) =>
                child.month === '...' ? '...' :
                    Math.ceil(child.month / 3))
            rows.push({
                ...sumary({ payload: [...months] }),
                hierarchy: [...hierarchy]
            })

            _.each(quarterlyUniqs, (childrens, quarterly) => {
                const [firstMonth, ...items] = childrens

                if (!!firstMonth) {
                    rows.push({
                        ...sumary({ payload: [firstMonth, ...items] }),
                        hierarchy: [...hierarchy, `Quý ${quarterly}`]
                    })
                }
                rows.push({
                    ...firstMonth,
                    hierarchy: [...hierarchy, `Quý ${quarterly}`, `Tháng ${firstMonth?.month}`],
                })
                rows.push(...(items?.map((row) => ({
                    ...row,
                    hierarchy: [...hierarchy, `Quý ${quarterly}`, `Tháng ${row?.month}`],
                    flag: row?.flag
                }))))
            })
        })
        return rows?.map((row, index) => ({ ...row, id: index }))
    }, [datasheets])
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ height: '500px' }}>
                <DataGridPro
                    treeData
                    rows={[...buildDataSheets]}
                    columns={columns}
                    getTreeDataPath={getTreeDataPath}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    defaultGroupingExpansionDepth={-1}
                />
            </Box>
        </Box>
    );
}
