import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import BalanceTimes from '../Balance/Header/BalanceTimes'
import StackAreaChart from '../shared/Charts/Comparetable/StackAreaChart'
import ProfitabilityApi from '@/apis/beta/Report/ProfitabilityApi'
import FinanceHeader from '../shared/Header'
import Actions from './Actions'
import { SCHEMES } from '@components/EChart'
import _ from 'lodash'
import { Box, FormControl, Typography } from '@mui/material'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'

const today = dayjs()
const Profitability = () => {

    const [queries, updateQueries] = useState({
        gte: today?.startOf('year'),
        lte: today?.endOf('year'),
        charts: {
            mode: 'month',
            current: { year: today?.get('year'), quarter: undefined, month: undefined },
            compare: { year: undefined, quarter: undefined, month: undefined, enabled: false }
        }
    })

    const { data: datasheets } = ProfitabilityApi.List({
        query: {
            gte: queries?.gte?.$d || queries?.gte,
            lte: queries?.lte?.$d || queries?.lte,
            response_format: 'json'
        }
    })
    const labels = [
        { label: 'Tỉ lệ lãi ròng', value: 'net_profit_margin' },
        { label: 'Tỉ lệ lãi gộp', value: 'gross_profit_margin' },
        { label: 'Tỉ lệ EBIT', value: 'ebit_margin' },
        { label: 'Tỉ lệ lãi từ HĐKD', value: 'operating_profit_margin' },
    ]
    const options = [
        { label: 'Tháng', value: 'month' },
        { label: 'Quý', value: 'quarter' },
        { label: 'Năm', value: 'year' },
    ]

    const [selectedLegends, setSelecteds] = useState([...labels?.map((label) => label?.value)])
    return (
        <>
            <FinanceHeader queries={queries} callbackQueries={updateQueries}>
                <Actions.Create />
            </FinanceHeader>
            <StackAreaChart
                series={[...labels?.map((label) => {
                    return ({
                        name: label?.label,
                        ...label,
                        data: Array.from({ length: 12 })?.map((x, i) => i * 10)
                    })
                })]}
                {...buildProfitabilitiesSheetStackLines({
                    datasheets,
                    queries
                })}
                selectedLegends={selectedLegends}
                setSelecteds={setSelecteds}
                datasheets={datasheets}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 14, minWidth: 80 }}>
                        Xem theo{":"}
                    </Typography>
                    <FormControl fullWidth sx={{ minWidth: 120 }}>
                        <SingleSelectCustomize
                            options={options}
                            value={options?.find(option => option.value === queries?.charts?.mode)}
                            onChange={(_, newValue) => {
                                updateQueries({
                                    ...queries,
                                    charts: {
                                        ...queries?.charts,
                                        mode: newValue?.value
                                    }
                                })
                            }}
                        />
                    </FormControl>
                </Box>
            </StackAreaChart>
        </>
    )
}

const ProfitabilityCategories = [
    { label: 'Tỉ lệ lãi ròng', value: 'net_profit_margin' },
    { label: 'Tỉ lệ lãi gộp', value: 'gross_profit_margin' },
    { label: 'Tỉ lệ EBIT', value: 'ebit_margin' },
    { label: 'Tỉ lệ lãi từ HĐKD', value: 'operating_profit_margin' }
]

const quarters = ["I", "II", "III", "IV"]
const buildProfitabilitiesSheetStackLines = ({
    datasheets = [],
    queries = { charts: { mode: '', current: {}, compare: {} } }
}) => {
    // defined variables
    let labels = []
    let series = []

    // uniq years on mode viewers
    const years = _.uniq(datasheets?.map((datasheet) => datasheet?.year))?.sort((a, b) => a - b)

    switch (queries?.charts?.mode) {
        case 'month':
            // completed labels
            years?.forEach((year) => {
                Array.from({ length: 12 })?.forEach((__, index) => {
                    labels.push({
                        year: year,
                        quarter: Math.ceil((index + 1) / 3),
                        month: index + 1,
                        label: `M${index + 1} ${years.length === 1 ? '' : `-Y${year}`}`
                    })
                })
            })
            // build series/ datasource for year
            ProfitabilityCategories?.forEach((balanceCategory, index) => {
                const dataset = labels?.map((label) => {
                    return _.sumBy(datasheets, (datasheet) => {
                        if (datasheet?.year === label?.year && datasheet?.month === label?.month) {
                            return datasheet?.[`${balanceCategory?.value}`]
                        }
                        return 0
                    })
                })

                series.push({
                    name: balanceCategory?.label,
                    value: balanceCategory?.value,
                    max: _.max(dataset),
                    data: dataset,
                    itemStyle: { color: SCHEMES.default[index] }
                })
            })
            break;
        case 'quarter':
            years?.forEach((year) => {
                Array.from({ length: 4 })?.forEach((__, index) => {
                    labels.push({
                        year: year,
                        quarter: index + 1,
                        month: index + 1,
                        label: `Q${quarters[index]} ${years.length === 1 ? '' : `-Y${year}`}`
                    })
                })
            })
            // build series/ datasource for year
            ProfitabilityCategories?.forEach((balanceCategory, index) => {
                const dataset = labels?.map((label) => {
                    return _.sumBy(datasheets, (datasheet) => {
                        if (datasheet?.year === label?.year && Math.ceil(datasheet?.month / 3) === label?.quarter) {
                            return datasheet?.[`${balanceCategory?.value}`]
                        }
                        return 0
                    })
                })
                series.push({
                    name: balanceCategory?.label,
                    value: balanceCategory?.value,
                    max: _.max(dataset),
                    data: dataset,
                    itemStyle: { color: SCHEMES.default[index] }
                })
            })
            break;
        case 'year':
            years?.forEach((year) => {
                labels.push({
                    year: year,
                    label: `Y${year}`
                })
            })
            // build series/ datasource for year
            ProfitabilityCategories?.forEach((balanceCategory, index) => {
                const dataset = labels?.map((label) => {
                    return _.sumBy(datasheets, (datasheet) => {
                        if (datasheet?.year === label?.year && datasheet?.year === label?.year) {
                            return datasheet?.[`${balanceCategory?.value}`]
                        }
                        return 0
                    })
                })
                series.push({
                    name: balanceCategory?.label,
                    value: balanceCategory?.value,
                    data: dataset,
                    itemStyle: { color: SCHEMES.default[index] }
                })
            })
            break;
        default:
            break;
    }

    return {
        series: series,
        legends: ProfitabilityCategories,
        labels: labels?.map((label) => label?.label),
        datasheets
    }
}

export default withSuspense(Profitability, MuiSkeleton["LoadingDotBox"])