import * as _ from 'lodash';

const labelOptions = {
    type: 'bar',
    barGap: 0,
    emphasis: { focus: 'series' },
    animationDuration: 800, // Thời gian chuyển đổi 800ms
    animationEasing: 'cubicOut',
}

export const Categories = [
    { label: 'Doanh Thu Thuần', value: 'revenue' },
    { label: 'Lợi Nhuận Gộp', value: 'profit' },
    { label: 'Lợi Nhuận Từ HĐKD', value: 'operating_profit' },
    { label: 'Lợi Nhuận Sau Thuế', value: 'earning_after_tax' }
]

export const BusinessPerformanceTimeSeries = ({
    mode = 'year',
    datasheets = []
}) => {

    let series = []

    const years = _.uniqBy(datasheets?.map((month) => month.year), (num) => parseInt(num))?.sort((a, b) => a - b)
    const quarters = [1, 2, 3, 4]
    switch (mode) {
        case 'year':
            series = years?.map((year) => ({
                name: year,
                ...labelOptions,
                data: dataTimeSeries({ model: mode, legend: year, datasheets })
            }))
            break;
        case 'quarter':
            years?.forEach((year) => {
                quarters?.forEach((quarter) => {
                    series.push({
                        name: `Quý ${quarter} Năm ${year}`,
                        ...labelOptions,
                        data: dataTimeSeries({
                            model: mode,
                            legend: `Quý ${quarter} Năm ${year}`,
                            quarter,
                            year: year,
                            datasheets
                        })
                    })
                })
            })
            break;
        default:
            break;
    }
    return series
}

const dataTimeSeries = ({ mode = 'year', legend, datasheets = [], ...props }) => {
    return Categories?.map((category) => {
        switch (mode) {
            case "year": return _.sumBy(datasheets?.filter(datasheet => datasheet?.year === legend), (record) => {
                return record?.[`${category.value}`]
            })
            case "quarter": return _.sumBy(datasheets?.filter(datasheet =>
                Math.ceil(datasheet?.month / 3) === props?.quarter && datasheet?.year === props?.year), (record) => {
                    return record?.[`${category.value}`]
                })
            default: return []
        }


        // return _.sumBy(monthly?.filter(monthCate => monthCate?.year === legend), (record) => {
        //     return record?.[`${category.value}`]
        // })
    })
}