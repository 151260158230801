import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import withPermission from '@/hocs/withPermission';
import withSuspense from "@/hocs/withSuspense";
import MuiSkeletonDefault from "@components/Skeletons/Default";
import { useEmployeeContext } from "@contexts/employee";
import React from 'react';
import { useBeforeUnload, useParams } from "react-router-dom";
import Feedback from "./Feedback";
import Header from "./Header";
import Information from "./Information";
import { useQueryClient } from "react-query";

const Detail = () => {

    const { employee } = useEmployeeContext()
    const { dayoff_id } = useParams()
    const queryClient = useQueryClient()
    const { data } = DayoffApi.Detail(dayoff_id)

    const { data: { leaders, master_inspectors } } = DayoffApi.CurrentRoles()

    const LeaderFeedback = Feedback["Leader"]
    const BodFeedback = Feedback["Bod"]

    const handleClearRedirect = () => {
        if (!localStorage.getItem('dayoff.referrer.redirect')) return
        try {
            localStorage.removeItem('dayoff.referrer.redirect')
        } catch (error) {
            console.log(error?.message)
        }
    }
    const callback = () => {
        queryClient.refetchQueries('day_off.detail')
        handleClearRedirect()
    }

    useBeforeUnload(handleClearRedirect)

    return (
        <React.Fragment>
            <Header dayoffId={dayoff_id} />
            <Information data={data} callback={callback} />
            {leaders?.includes(employee?.employee_id)
                && <LeaderFeedback data={data} callback={callback} managerIds={leaders || []} />}
            {master_inspectors?.includes(employee?.employee_id)
                && <BodFeedback data={data} callback={callback} managerIds={master_inspectors || []} />}
        </React.Fragment>
    )
}
export default withSuspense(withPermission(Detail, { feature: 'dayoff', action: 'show' }), MuiSkeletonDefault)
