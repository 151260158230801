import EChart from '@components/EChart'
import { Paper } from '@mui/material'
import React from 'react'
import { Categories } from '../../functions'
import _ from 'lodash'

const YearlyComparetableChart = ({ datasheets = [] }) => {
    return (
        <Paper sx={{ p: 1.25, height: '700px' }}>
            <EChart options={{
                tooltip: { trigger: 'axis', axisPointer: { type: 'cross' } },
                title: { text: `So Sánh Kết Quả Kinh Doanh Theo Năm`, left: 'center', textStyle: { fontSize: 14, color: "#777680" } },
                xAxis: [
                    {
                        type: 'category', axisTick: { show: false },
                        data: Categories?.map((cate) => cate?.label)
                    }
                ],
                yAxis: [{ type: 'value' }],
                legend: { data: Categories, top: 'bottom', left: 'center' },
                series:
                    _.sortedUniqBy(_.uniqBy(datasheets?.map((month) => month.year), (num) => parseInt(num)))?.map((year) => {
                        return ({
                            name: year,
                            type: 'bar',
                            data: Categories?.map((category) => {
                                return _.sumBy(datasheets?.filter(datasheet =>
                                    datasheet?.year === year), (record) => {
                                        return record?.[`${category.value}`]
                                    })
                            })
                        })
                    })

            }} />
        </Paper>
    )
}

export default YearlyComparetableChart