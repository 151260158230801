import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from "@/components/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    Typography,
  } from "@mui/material";
import moment from "moment";
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
export default function DialogResendReport(props) {
    const [date,setDate] = React.useState({
        date_start: moment(new Date()).format("YYYY-MM-DD"),
        date_end: moment(new Date()).format("YYYY-MM-DD")
    });
    const [value, setValue] = React.useState('exported');
    const [pending, setPending] = React.useState(false);
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    // const timeLimit = 30*24*60*60*1000; //limit 30 days
    const time = 24*60*60*1000;
    const handleClose = () => {
    props.handleClose();
    };
  const onSending = (mode = "exported", task_id = null, gte = null, lte = null ) => {
    setPending(true);
    props.setNotification({
        open: true,
        message: "Đang gửi yêu cầu ...",
        severity: "warning",
    })
    ReportWatchApi.ResendReport({report_uuid: props.report_uuid,body :{
        mode,
        report: {
            task_id:task_id, 
            gte:gte, 
            lte:lte, 
            type: props.report_interval
        }
    }}).then((res) => {
        if(res.status !== 200){
            setPending(false);
            return props.setNotification({
                open: true,
                message: "Gửi lại báo cáo thất bại",
                severity: "error",
            })
        }
        setPending(false);
        props.setNotification({
            open: true,
            message: "Đang thực hiện gửi lại báo cáo ...",
            severity: "success",
        })
        if(props?.refetch){
            props?.refetch();
        }
    })
  }
  
  const checkReportSent = () => {
    ReportWatchApi.GetReportHistory(props.report_uuid).then((res) => {
        if(!res?.data?.tasks || res?.data?.tasks?.length === 0){
            return props.setNotification({
                open: true,
                message: "Không có report tương ứng để gửi!",
                severity: "error",
            })
        }
        if(res.data?.tasks?.length > 0){
            res.data?.tasks?.sort((a, b) => new Date(a.task_created_at) - new Date(b.task_created_at));
        }
        onSending("exported", res?.data?.tasks?.[res?.data?.tasks?.length - 1]?.task_id, date.date_end, date.date_end);
    })    
  }
  const sendReportCustomize = () => {
    onSending("customize", null, date.date_start, date.date_end);
  }
  const handleAccept = () => {
    if(value === 'exported'){
        checkReportSent();
    }else{
        sendReportCustomize();
    }
  }
  const checkDate = (date) => {
    const datePick = new Date(date);
    const today = new Date();
    if(((datePick.getTime() - today.getTime()) / time ) >= -30){
      return false;
    }
    return true; // true if it last 30 days
  }
  const handleChangeDateStart = (newValue) => {
    if(!checkDate(newValue)){
      setDate({
        ...date,
        date_start: newValue
      })
    }else{
      props.setNotification({
        open: true,
        message: "Khoảng thời gian không quá 30 ngày tính từ hôm nay",
        severity: "error",
      })
    }
  }
  const handChangeDateEnd = (newValue) => {
    if(!checkDate(newValue)){
      if(new Date(date.date_start).getTime() < new Date(newValue).getTime()){
        setDate({
          ...date,
          date_end: newValue
        })
      }else{
        props.setNotification({
          open: true,
          message: "Khoảng thời gian sau phải lớn hơn khoảng thời gian trước",
          severity: "error",
        })
      }
    }else{
        props.setNotification({
          open: true,
          message: "Khoảng thời gian không quá 30 ngày tính từ hôm nay",
          severity: "error",
        })
    }
  }
    return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Gửi lại report {props.report_name}
        </DialogTitle>
        <DialogContent>
        <FormControl>
        <FormLabel >Chọn phương thức gửi báo cáo</FormLabel>
        <RadioGroup
            value={value}
            onChange={handleChange}
        >
            <FormControlLabel value="exported" control={<Radio />} label="Báo cáo gần nhất đã gửi" />
            <FormControlLabel value="customize" control={<Radio />} label="Tùy chỉnh thời gian" />
        </RadioGroup>
        </FormControl>
        {value === 'customize' && <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography sx={{ mb: 0.5, fontSize: 14 }}>Từ ngày</Typography>
            <DatePicker onChange={(newValue) => handleChangeDateStart(moment(newValue?.["$d"])?.format("YYYY-MM-DD"))} value={date.date_start} />
            <Typography sx={{ mb: 0.5, fontSize: 14 }}>Đến ngày</Typography>
            <DatePicker onChange={(newValue) => handChangeDateEnd(moment(newValue?.["$d"])?.format("YYYY-MM-DD"))} value={date.date_end} />
        </LocalizationProvider>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button 
          disabled={pending}
          onClick={() => handleAccept()}>
            Gửi
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}