import DatePicker from '@components/datepickers/DateRangPicker'
import { Box, Button, ButtonGroup, FormControl } from '@mui/material'
import React from 'react'
import Comparetable from '../Actions/Comparetable'

const InitRanges = [
    { value: 'year', label: 'Năm' },
    { value: 'quarter', label: 'Quý' },
    { value: 'month', label: 'Tháng' },
    { value: 'clear', label: 'Reset' }
]

const TimeRanges = ({
    state, setState
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5 }}>
            <FormControl>
                <DatePicker
                    value={[state?.gte, state?.lte]}
                    onChange={(newValues) => setState(preState => ({ ...preState, gte: newValues[0], lte: newValues[1] }))}
                />
            </FormControl>
            <ButtonGroup>
                {InitRanges?.map((range, index) =>
                    <Button size='small' key={index}
                        onClick={() => setState(preState => ({ ...preState, mode: range?.value === 'clear' ? 'year' : range?.value }))}
                        disabled={range?.value === 'clear' && state?.mode === 'year'}
                        variant={(range?.value !== state?.mode || range?.value === 'clear') ? 'outlined' : 'contained'}
                        sx={{ width: 80 }}
                    >
                        {range.label}
                    </Button>
                )}
            </ButtonGroup>
            <Comparetable state={state} setState={setState} />
        </Box>
    )
}

export default TimeRanges