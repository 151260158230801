import EChart from '@components/EChart';
import { Grid, Paper } from '@mui/material';

const StackChart = ({
    fullWidth = false,
    options = {},
    data = [],
    children,
    handleBarClick = () => { },
    ...props
}) => {
    return (
        <Grid container spacing={2}>
            {children}
            <Grid item xs={12} md={fullWidth ? 12 : 8} {...props}>
                <Paper sx={{ p: 1.5, height: '700px' }}>
                    <EChart options={{
                        series: data, ...options,
                        legend: {
                            ...options?.legend,
                            bottom: '10%'
                        }
                    }}
                        onEvents={{ click: handleBarClick }}
                        style={{ transition: 'all 0.8s ease-in-out' }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default StackChart;
