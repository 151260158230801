import { AppBar, Box, Button, Container, Dialog, FormControl, Grid, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, TextField, Toolbar, Typography, InputAdornment, OutlinedInput } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CancelIcon from '@mui/icons-material/Cancel';
import DnsIcon from '@mui/icons-material/Dns';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import Autocomplete from '@mui/material/Autocomplete';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { Controller, useForm } from 'react-hook-form';
import { useNotificationContext } from '@contexts/notification';
import SearchCustomers from '../SearchCustomers';
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const renderOption = (option) => {
  return (
    <MenuItem
      {...option}
      key={option["data-option-index"]}
      sx={{ '&:hover': { background: "#f0f0f0" } }}
    >
      {option["key"]}
    </MenuItem>

  );
};
const DialogEditReportData = (props) => {
  const { setNotification } = useNotificationContext();
  const [mail, setMail] = React.useState('');
  const [domain, setDomain] = React.useState('');
  const { data: report_waf, refetch: refetchWaf } = ReportWatchApi?.WafAttribute({report_uuid:props?.report_uuid});
  const { control, formState: { errors }, setValue, watch } = useForm(
    {
        defaultValues: {
            report_priority: report_waf?.report_priority || 0,
            report_receivers: report_waf?.report_receivers || [],
            domains: report_waf?.waf_attribute?.domains || [],
            source_domains: report_waf?.waf_attribute?.source_domains || '',
            mlytics_id: report_waf?.waf_attribute?.mlytics_id || '',
            report_referrer_id: report_waf?.report_referrer_id || '',
        }
    });
  const handleOnchangeMail = (value) => {
    setMail(value);
  }
  const handleOnchangeDomain = (value) => {
    setDomain(value);
  }
  const handleAddMail = () => {
    if (mail.replaceAll(" ", "") === "") return;
    if (watch("report_receivers").includes(mail)) return;
    setValue("report_receivers", [...watch("report_receivers"), mail]);
    setMail('');
  }
  const handleAddDomain = () => {
    if (domain.replaceAll(" ", "") === "") return;
    if (watch("domains").includes(domain)) return;
    setValue("domains", [...watch("domains"), domain]);
    setDomain('');
  }
  const handleRemoveMail = (index) => {
    setValue("report_receivers", watch("report_receivers").filter((item, i) => i !== index));
  }
  const handleRemoveDomain = (index) => {
    setValue("domains", watch("domains").filter((item, i) => i !== index));
  }
  const handleOnchangeId = (value) => {
    setValue("mlytics_id", value);
  }
  const handleOnchangeSource_domains = (value) => {
    setValue("source_domains", value);
  }
  const onSubmit = async (data) => {
    await ReportWatchApi.UpdateWafAttribute(props?.report_uuid, {
      report: {
        report_priority: data?.report_priority,
        report_receivers: data?.report_receivers,
        report_referrer_id: data?.report_referrer_id,
      },
      waf_attribute: {
        domains: data.domains,
        source_domains: data.source_domains,
        mlytics_id: data.mlytics_id
      }
    }).then((response) => {
      if (!response?.data.report) {
        return setNotification({
          open: true,
          message: response?.errors?.[0]?.message || "Cập nhật thất bại.",
          severity: "error",
        });
      }
      refetchWaf()
      if (props.refetch) props.refetch();
      props.handleClose();
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message || `Cập nhật ${props.report_name} thành công!`,
        severity: "success",
      });
    }).catch((error) => {
      return setNotification({
        open: true,
        message: error?.response?.data?.errors[0]?.message || `Cập nhật ${props.report_name} thất bại.`,
        severity: "error",
      });
    })
  }
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const {
    data,
  } = ReportWatchApi.MlyticList({
    organization_name: autoCompleteValue || ''
  });

  const onInputChange = (current_input) => {
    if (!current_input) return;
    if (!current_input.target?.value) return;
    setAutoCompleteValue(current_input.target?.value || '');
    handleOnchangeId(current_input.target?.value || '');
  }

  const onAutocompleteOnChange = (value) => {
    if (!value?.organizationName) return;
    setAutoCompleteValue(value.organizationName);
    handleOnchangeId(value.customerId);
  }
  const handleChangeCustomer = (value) => {
    setValue("report_referrer_id",value?.customer_id);
  }
  return (
    <>
      <Dialog
        hideBackdrop
        fullScreen
        keepMounted
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Thông tin
            </Typography>
            <Button autoFocus color="inherit"
              onClick={() => onSubmit(watch())}
            >
              Lưu chỉnh sửa
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm" sx={{ p: 10 }}>
          <Typography 
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 20
            }}
          >
            {props?.report_name}
          </Typography>
          <Autocomplete
            options={data?.Customers}
            getOptionLabel={(option) => option?.organizationName}
            value={{ organizationName: autoCompleteValue || watch("mlytics_id") }}
            freeSolo
            renderOption={renderOption}
            onChange={(_, newValue) => {
              if (!newValue) {
                setAutoCompleteValue('');
                handleOnchangeId('');
              } else {
                onAutocompleteOnChange(newValue);
              }
            }}
            onInputChange={onInputChange}
            sx={{ width: '100%', mt: 2 }}
            renderInput={(params) => <TextField {...params} label="Mlytics_id" placeholder="Nhập tên để search" onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} />}
          />
          <Box sx={{ mt: 2, backgroundColor: '#f5f5f5', p: 2, borderRadius: '4px' }}>
            <Controller
              name='report_referrer_id'
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                  <>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">ID customer</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">ID: </InputAdornment>}
                            label="ID customer"
                            {...field}
                            placeholder='Chọn customer bên dưới'
                            readOnly
                        />
                      </FormControl>
                      {errors.report_referrer_id?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn customer</p>}
                      </>
              }
            />
            <Box sx={{ mt: 2 }}>
              <SearchCustomers
                  limit={5}
                  size={8}
                  maxHeight={400}
                  onChange={handleChangeCustomer}
              />  
            </Box> 
          </Box>
          <TextField id="outlined-basic" sx={{ mt: 2 }}
            label="Source Domains"
            variant="outlined"
            value={watch("source_domains")}
            onChange={(e) => handleOnchangeSource_domains(e.target.value)}
          />
          <TextField
            type='number'
            id="outlined-basic" label="Mức độ ưu tiên (0-10)" sx={{ mt: 2 }} variant="outlined"
            placeholder='Mức độ ưu tiên càng cao được xử lý trước'
            value={watch("report_priority")}
            onChange={(e) => {if (e.target.value >= 0 && e.target.value <= 10) setValue("report_priority", e?.target?.value)}}
          />
          <TextField
            type='mail'
            id="outlined-basic" label="Mail người liên hệ" sx={{ mt: 2 }} variant="outlined"
            placeholder='Thêm mail vào danh sách'
            value={mail}
            onChange={(e) => handleOnchangeMail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddMail();
              }
            }}
          />
          <Button variant='contained' sx={{ mt: 2 }}
            onClick={() => handleAddMail()}
          >
            Thêm Mail
          </Button>
          <TextField id="outlined-basic" sx={{ mt: 2 }} label="Domain" variant="outlined"
            value={domain}
            placeholder='Thêm domain vào danh sách'
            onChange={(e) => handleOnchangeDomain(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddDomain();
              }
            }}
          />
          <Button variant='contained' sx={{ mt: 2 }}
            onClick={() => handleAddDomain()}
          >Thêm Domain</Button>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Mail người liên hệ
              </Typography>
              <Demo>
                <List dense={true}>
                  {
                    watch("report_receivers")?.map((mail, index) =>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMail(index)}>
                            <CancelIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <ContactMailIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={mail}
                        />
                      </ListItem>
                    )}
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Domains
              </Typography>
              <Demo>
                <List dense={true}>
                  {
                    watch("domains")?.map((domain, index) =>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDomain(index)}>
                            <CancelIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <DnsIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={domain}
                        />
                      </ListItem>
                    )}
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
}

export default withSuspense(DialogEditReportData, MuiSkeleton["LinearProgress"]);
