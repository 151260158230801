import { INITIALIZE_SERVICE_GROUPS, INVOICE_STATUS_TYPES } from '@/libs/constants'
import DatePicker from '@components/datepickers/DateRangPicker'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { FormControl, Grid } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { queryToCondition } from '../../PopperPopupState/functions'

const BASE = {
    MultipleSelectCustomize: ({ value: values = [], onChange, options = [], ...props }) => {
        const selecteds = options?.filter(option => values?.includes(option?.value)) || []
        return <MultipleSelectCustomize
            value={selecteds}
            options={options}
            onChange={(__, newValues) => onChange(newValues?.map(option => option?.value) || [])}
            {...props}
        />
    },
    DatePicker: ({ value, onChange, ...props }) => {
        return (
            <DatePicker value={value} handler={onChange} {...props} />
        )
    }
}

const InvoiceFilter = ({
    updateQuery,
    callback,
}) => {

    const [state, setState] = useState([
        {
            key: 'invoices.invoice_status',
            operator: '$in',
            type: 'object',
            component: 'MultipleSelectCustomize',
            options: INVOICE_STATUS_TYPES,
            value: [],
            onChange: (newValues) => setState(preState => [
                {
                    ...preState[0],
                    value: newValues
                },
                ...preState?.slice(1)]),
            params: {
                input: {
                    label: 'Trạng thái hóa đơn',
                    placeholder: 'Chọn trạng thái...'
                }
            }
        }, {
            key: 'tw_services.service_product_id',
            operator: '$in',
            type: 'object',
            component: 'MultipleSelectCustomize',
            options: INITIALIZE_SERVICE_GROUPS,
            value: [],
            onChange: (newValues) => setState(preState => [
                ...preState?.slice(0, 1),
                {
                    ...preState[1],
                    value: newValues
                },
                ...preState?.slice(2)]),
            params: {
                input: {
                    label: 'Nhóm Dịch Vụ',
                    placeholder: 'Chọn nhóm dịch vụ...'
                }
            }
        }, {
            key: 'invoices.cskh_confirmed',
            operator: '$in',
            type: 'object',
            component: 'MultipleSelectCustomize',
            options: [{ value: false, label: "Chưa được xác nhận" }, { value: true, label: "Đã được xác nhận" }],
            value: [],
            onChange: (newValues) => setState(preState => [
                ...preState?.slice(0, 2),
                {
                    ...preState[2],
                    value: newValues
                },
                ...preState?.slice(3)]),
            params: {
                input: {
                    label: 'CSKH Xác Nhận',
                    placeholder: 'Chọn trạng thái...'
                }
            }
        }, {
            key: 'invoice_apply_startdate',
            operator: '$btw',
            type: 'date',
            component: "DatePicker",
            value: [null, null],
            onChange: (newValues) => setState(preState => [
                ...preState?.slice(0, 3),
                {
                    ...preState[3],
                    value: newValues
                },
                ...preState?.slice(4),
            ]),
            params: {
                localeText: {
                    start: 'Thời Gian Bắt Đầu Tính Phí Dịch Vụ',
                    end: ''
                }
            }
        }, {
            key: 'invoice_apply_duedate',
            operator: '$btw',
            type: 'date',
            component: "DatePicker",
            value: [null, null],
            onChange: (newValue) => setState(preState => [
                ...preState?.slice(0, 4),
                {
                    ...preState[4],
                    value: newValue
                }]),
            params: {
                localeText: {
                    start: 'Thời Gian Kết Thúc Tính Phí Dịch Vụ',
                    end: ''
                }
            }
        }
    ])

    useEffect(() => {
        let andConditions = []
        _.forEach(state, (obj) => {
            if (!!obj.value) {
                if (obj.type === "object" && obj.value.length === 0) {
                    return;
                }
                if (obj.type === "date" && !obj.value[0]) {
                    return;
                }
                const q = queryToCondition({
                    fieldSelect: obj.key,
                    input: {
                        string: obj.value,
                        objects: obj.value?.map(x => ({ value: x })),
                        type: obj.type,
                        startdate: obj.type === "date" ? obj.value[0] : null,
                        enddate: obj.type === "date" ? obj.value[1] : null
                    },
                    operator: { operator: obj.operator }
                })
                if (obj.type === "date" && obj.operator === "$btw") {
                    andConditions.push(...q)
                    return;
                }
                andConditions.push(q)
            }
        })

        const filters = {
            $and: andConditions
        }

        updateQuery(filters)
        callback(filters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {state?.map((column, index) => {
                if (column?.hide) return null;

                const Component = BASE[column?.component]
                if (!Component) return null;

                return (
                    <Grid item xs={12} md={4} xl={3} key={index}>
                        <FormControl fullWidth>
                            <Component
                                value={column.value}
                                onChange={column?.onChange}
                                options={[...column?.options || []]}
                                {...column?.params || {}}
                            />
                        </FormControl>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default InvoiceFilter