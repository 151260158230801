import { DifferenceOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'

const Comparetable = ({ state, setState }) => {
    return (
        <Button startIcon={<DifferenceOutlined fontSize='small' />}
            disabled={state?.layout !== 'chart'}
            variant={state?.comparetable === 'comparetable' ? 'contained' : 'outlined'}
            size='small' onClick={() => {
                setState(preState => ({
                    ...preState,
                    comparetable: preState?.comparetable === 'comparetable' ? '' : 'comparetable'
                }))
            }}>
            So Sánh Dữ Liệu
        </Button>
    )
}

export default Comparetable