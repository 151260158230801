import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, CircularProgress } from "@mui/material";

const ButtonBaseExport = ({
  disabled,
  handler,
  loading,
  variant = "contained",
  styled = {}
}) => {
  return (
    <Button
      startIcon={
        !loading ? <FileDownloadOutlinedIcon /> : <CircularProgress size={18} />
      }
      disabled={disabled}
      onClick={handler}
      variant={variant}
      size="small"
      sx={{
        "&.MuiButton-root": {
          height: 36,
          m: "auto 0",
        },
        ...styled
      }}
    >
      Tải Dữ Liệu
    </Button>
  );
};

export default ButtonBaseExport;
