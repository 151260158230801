import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class InvoiceApi {
  List = ({
    sorters = null,
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
  }) => {
    return useQueryWithCustomized(
      ["invoices.list", query, page, pageSize, searchQuery, sorters],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}${sorters ? `&sorters=${encodeBase64(sorters)}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  Confirmations = ({
    sorters = null,
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
  }) => {
    return useQueryWithCustomized(
      ["invoices.list", query, page, pageSize, searchQuery, sorters],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/confirmations?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}${sorters ? `&sorters=${encodeBase64(sorters)}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  ReportFinance = ({ query = null, searchQuery = null }) => {
    return useQueryWithCustomized(
      ["invoices.statistic", query, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/statistic?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Detail = (invoiceId) => {
    return useQueryWithCustomized(
      ["invoice.detail", invoiceId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.invoice;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };
  Information = ({ invoiceId = -1 }) => {
    return useQueryWithCustomized(
      ["invoice.informations", invoiceId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/invoices/${invoiceId}`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.invoice;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
        enabled: !!localStorage.getItem("SA"),
      }
    );
  };

  CreateTransaction = async ({ invoiceId, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/transactions`,
      "POST",
      {
        transaction: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Cancelled = async ({ invoiceId = 1, status = "" }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/${status}`,
      "DELETE",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Merge = async ({ invoiceIds }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/merge`,
      "POST",
      {
        invoice_ids: invoiceIds,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  UpdateInvoiceItems = async ({ invoiceId, deleted, items }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}`,
      "PUT",
      {
        invoice: {
          deleted: deleted,
          items: items,
        },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return { invoice: data?.data?.invoice };
      })
      .catch((error) => {
        return error.response;
      });
  };

  ChangeCommissions = async ({ invoiceId, employeeIds }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}`,
      "PUT",
      {
        invoice: {
          commission_employee_ids: employeeIds,
        },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
  Transactions = ({ invoiceId = null }) => {
    return useQueryWithCustomized(
      ["invoice.transactions.list", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/transactions`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.transactions;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Mailers = ({ invoiceId = null }) => {
    return useQueryWithCustomized(
      ["invoice.mailers.list", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/invoice_mailers`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.mailers;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  InvoiceUpdates = ({ invoiceId = null }) => {
    return useQueryWithCustomized(
      ["invoice.loggers", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/loggers`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.loggers;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Services = ({ invoiceId = null }) => {
    return useQueryWithCustomized(
      ["invoice.services.list", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/reference_services`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.services;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  InvoiceItems = ({ invoiceId = null }) => {
    return useQueryWithCustomized(
      ["invoice.invoice_items", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/invoice_items`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.invoice_items;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  SendMail = async ({ invoiceId = -1, data = {}, type = "" }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/sendmail?mailer_type=${type}`,
      "POST",
      {
        mailer: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Preview = async ({ invoiceId, type = "invoice" }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/preview?preview_type=${type}`,
      "GET",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  QuickView = ({ invoiceId }) => {
    return useQueryWithCustomized(
      ["invoice.quickview", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/invoice_items`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.invoice_items;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  PrecheckMailer = ({ invoiceId }) => {
    return useQueryWithCustomized(
      ["invoice.precheck-mailer", invoiceId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/${invoiceId}/precheck_mailer`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  InvoiceMailers = ({ page, pageSize, invoiceId, query }) => {
    return useQueryWithCustomized(
      ["invoice.mailers", page, pageSize, invoiceId, query],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/invoices/mailers?page=${page}&page_size=${pageSize}${!!invoiceId ? `&invoice_id=${invoiceId}` : ""
          }${!!query ? `&filters=${encodeBase64(query)}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              tasks: data?.data?.tasks || [],
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Download = async ({ invoiceId = -1, type = 'invoice' }) => {
    const response = await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/pdf?preview_type=${type}`,
      "GET",
      {},
      {
        "Content-Type": "application/pdf",
        "CONTENT-DISPOSITION": "attachment",
      },
      { withCredentials: true, responseType: "blob" }
    );
    return response?.data;
  };

  Approved = async ({ invoiceId }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/approved`, "PUT")
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Export = async ({ invoiceId, invoiceNumber }) => {
    return await requestWithToken(
      `${API_V3_URL}/invoices/${invoiceId}/export`, "PUT", {
      invoice: { invoice_number: invoiceNumber }
    })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvoiceApi();
