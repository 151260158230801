import ReportApi from '@/apis/beta/Report/ReportApi'
import { format_date_short } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import MuiScrollTabs from '@components/shared/MuiScrollTabs'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { PieChartOutlineOutlined, StackedBarChartOutlined, StackedLineChartOutlined, TableRowsOutlined } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Divider, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import Filter from '../Invoice/Filter'
import DataGrid from './DataGrid'
import PieChart from './Charts/Pie'
import StackChart from './Charts/Stack'

const DataModes = [
    { value: 'grid', label: <TableRowsOutlined fontSize='small' /> },
    { value: 'bar', label: <StackedBarChartOutlined fontSize='small' /> },
    { value: 'pie', label: <PieChartOutlineOutlined fontSize='small' /> },
    { value: 'line', label: <StackedLineChartOutlined fontSize='small' /> },
]
const TimelineModes = [
    { value: 'monthly', label: 'Tháng' },
    { value: 'quarterly', label: 'Quý' },
]
const Industry = () => {
    const today = dayjs()
    const [mode, setMode] = useState({
        data: 'grid',
        timeline: 'monthly',
    })

    const [state, setState] = useState({
        gte: today.startOf('year'),
        lte: today.endOf('year'),
        q: '',
        services: '',
        industries: '',
        employees: ''
    })
    const { data } = ReportApi.Finance({
        query: {
            ...state,
            gte: format_date_short(state?.gte?.$d),
            lte: format_date_short(state?.lte?.$d),
            response_format: 'json'
        }
    })

    const renderDataMode = () => {
        switch (mode?.data) {
            case 'pie': return <PieChart data={data} />
            case 'bar':
            case 'line':
                return <StackChart data={data} mode={mode} />
            case 'grid':
                return <DataGrid data={data} mode={mode} setMode={setMode} />
            default:
                break;
        }
    }
    return (
        <>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium', mb: 2 }}>
                Báo Cáo Tài Chính
            </Typography>
            <MuiScrollTabs
                prefix='reports/finance'
                initialTabs={[
                    {
                        label: 'theo hoá đơn',
                        href: 'invoices',
                        value: 'invoices'
                    },
                    {
                        label: 'theo sản phẩm/ dịch vụ',
                        href: 'services',
                        value: 'services'
                    },
                    {
                        label: 'theo lĩnh vực',
                        href: 'industries',
                        value: 'industries'
                    }
                ]} />
            <Filter
                services={data?.services}
                industries={data?.industries}
                state={state} setState={setState} />
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column-reverse', md: 'row' }, pb: 2 }}>
                <ButtonGroup>
                    {DataModes?.map((dataMode, index) => (
                        <Button
                            key={index}
                            size='small'
                            variant={dataMode?.value === mode?.data ? 'contained' : 'outlined'}
                            sx={{ width: 100 }}
                            onClick={() => setMode(preMode => ({ ...preMode, data: dataMode?.value }))}
                        >
                            {dataMode.label}
                        </Button>
                    ))}
                </ButtonGroup>
                <ButtonGroup>
                    {TimelineModes?.map((dataMode, index) => (
                        <Button
                            key={index}
                            size='small'
                            disabled={mode?.data === 'pie'}
                            variant={dataMode?.value === mode?.timeline ? 'contained' : 'outlined'}
                            sx={{ width: 100 }}
                            onClick={() => setMode(preMode => ({ ...preMode, timeline: dataMode?.value }))}
                        >
                            {dataMode.label}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
            <Divider sx={{ m: '0 0 16px 0' }} />
            {renderDataMode()}
        </>
    )
}

export default withSuspense(Industry, MuiSkeleton["DataGrid"])