import { format_numeral_price } from '@/functions'
import { PRODUCT_UNITS } from '@/libs/constants'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'

export const SERVICE_INFORMATION_HIDDENS = ["software", "cloudsecurities", "ssl"]

const ServiceInformation = ({ serviceInformations = [] }) => {

    if (serviceInformations?.length === 0) return null

    return (
        <React.Fragment>
            <Typography sx={{
                fontSize: 14,
                mb: 0.5,
                color: "#777680",
            }}>
                Thông Tin Sản Phẩm/ Dịch Vụ
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5 }}>
                {serviceInformations?.map((item, index) => {
                    if (parseInt(item?.warehouse_capacity_value) === 0) return null
                    if (SERVICE_INFORMATION_HIDDENS.includes(item?.product_infomation_type)) return null
                    return (
                        <Chip size='small' variant='outlined'
                            key={index}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            label={<Typography sx={{
                                fontSize: 14,
                                fontWeight: 'medium',
                                textTransform: 'uppercase',
                            }} color="primary">
                                {item.name}
                                <Typography sx={{ fontSize: 14, }} component="span">{":"}</Typography>
                                <Typography sx={{ fontSize: 14, ml: 1.25 }} color="text.black" component="span">
                                    {item.unit === "qty" && `x`}{format_numeral_price(item?.warehouse_capacity_value)}
                                    {PRODUCT_UNITS.slice(1).find((k) => k.value === item.unit)?.label}
                                </Typography>
                            </Typography>}
                        />
                    )
                })}
            </Box>
        </React.Fragment>
    )
}

export default ServiceInformation