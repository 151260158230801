import { format_date_short } from '@/functions'
import useDebounce from '@/hooks/useDebounce'
import DatePicker from '@components/datepickers/DateRangPicker'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { Box, FormControl, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import FinanceInvoiceExport from '../Export'

const Filter = ({
    state,
    industries = [],
    services = [],
    setState = () => { }
}) => {
    const [search, setSearch] = useState('')
    const debounceSearchQuery = useDebounce(search, 400)
    useEffect(() => {
        //
        setState(preState => ({ ...preState, q: debounceSearchQuery }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceSearchQuery])

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: { xs: 1.5, md: 2 }, gap: 2
        }}>
            <Box sx={{ display: 'flex', gap: 1.5, width: '100%' }}>
                <FormControl fullWidth>
                    <TextField
                        label='Tìm kiếm'
                        InputLabelProps={{ shrink: true }}
                        size='small'
                        placeholder='Search Invoice ID, tên khách hàng...'
                        value={search}
                        onChange={(event) => setSearch(event?.target?.value)}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <MultipleSelectCustomize
                        options={[...services?.map(industry => ({ label: industry?.toUpperCase(), value: industry }))]}
                        value={!state?.services ? [] : state?.services?.split(',')?.map(industry => ({ label: industry?.toUpperCase(), value: industry }))}
                        onChange={(event, newValues) => setState(preState => ({
                            ...preState,
                            services: newValues?.map(industry => industry?.value)?.join(',')
                        }))}
                        input={{
                            label: 'Nhóm Dịch Vụ',
                            placeholder: 'Chọn nhóm dịch vụ...'
                        }}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <MultipleSelectCustomize
                        options={[...industries?.map(industry => ({ label: industry?.toUpperCase(), value: industry }))]}
                        value={!state?.industries ? [] : state?.industries?.split(',')?.map(industry => ({ label: industry?.toUpperCase(), value: industry }))}
                        onChange={(event, newValues) => setState(preState => ({
                            ...preState,
                            industries: newValues?.map(industry => industry?.value)?.join(',')
                        }))}
                        input={{
                            label: 'Lĩnh Vực',
                            placeholder: 'Chọn nhóm dịch vụ...'
                        }}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <MultipleSelectCustomize
                        disabled
                        input={{
                            label: 'Nhân Viên',
                            placeholder: 'Chọn nhóm dịch vụ...'
                        }}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <DatePicker value={[state?.gte, state?.lte]}
                        handler={(newValues) => {
                            setState(preState => ({ ...preState, gte: newValues[0], lte: newValues[1] }))
                        }}
                    />
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1.5 }}>
                <FinanceInvoiceExport
                    query={{
                        ...state,
                        gte: format_date_short(state?.gte?.$d),
                        lte: format_date_short(state?.lte?.$d),
                        response_format: ''
                    }}
                />
            </Box>
        </Box>
    )
}

export default Filter