import EChart from '@components/EChart'
import { Box, Button, ButtonGroup, Grid, Paper, Typography } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { Categories } from '../../functions'


const MonthRanges = Array.from({ length: 12 }).map((__, i) => ({ value: `month-${i + 1}`, label: `Tháng ${i + 1}` }))
const MonthComparetableChart = ({ datasheets = [] }) => {
    const [monthSelecteds, setMonths] = useState(MonthRanges?.map((month) => month?.value)?.join(','))
    return (
        <Box sx={{}}>
            <Typography sx={{ color: "#ef5350", mb: 1.25 }}>
            </Typography>
            <ButtonGroup sx={{ mb: 2 }}>
                {MonthRanges?.map((quarter, index) => {
                    return (
                        <Button size='small'
                            key={index}
                            variant={monthSelecteds?.includes(quarter?.value) ? 'contained' : 'outlined'}
                            onClick={() => !monthSelecteds?.includes(quarter?.value) ? setMonths(preMonths => [...preMonths, quarter?.value])
                                : setMonths(preMonths => [...preMonths?.filter(x => x !== quarter?.value)])
                            }
                            sx={{
                                width: 80,
                                ...quarter?.sx
                            }}
                        >
                            {quarter?.label}
                        </Button>
                    )
                })}
            </ButtonGroup>
            <Grid container spacing={2}>
                {MonthRanges?.map((month, index) => {

                    const hidden = !monthSelecteds.includes(month?.value)
                    if (!!hidden) return null
                    return (
                        <Grid key={index} item xs={12} md={monthSelecteds?.length >= 3 ? 4 : 12}>
                            <Paper sx={{ p: 1.25, height: '400px' }}>
                                <EChart options={{
                                    tooltip: { trigger: 'axis', axisPointer: { type: 'cross' } },
                                    title: { text: month?.label, left: 'center', textStyle: { fontSize: 14, color: "#777680" } },
                                    xAxis: [
                                        {
                                            type: 'category', axisTick: { show: false },
                                            data: Categories?.map((cate) => cate?.label)
                                        }
                                    ],
                                    yAxis: [{ type: 'value' }],
                                    legend: { data: Categories, top: 'bottom', left: 'center' },
                                    series:
                                        _.sortedUniqBy(_.uniqBy(datasheets?.map((month) => month.year), (num) => parseInt(num)))?.map((year) => {
                                            return ({
                                                name: year,
                                                type: 'bar',
                                                data: Categories?.map((category) => {
                                                    return _.sumBy(datasheets?.filter(datasheet =>
                                                        datasheet?.month === index + 1 && datasheet?.year === year), (record) => {
                                                            return record?.[`${category.value}`]
                                                        })
                                                })
                                            })
                                        })

                                }} />
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default MonthComparetableChart