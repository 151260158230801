import EChart from '@components/EChart'
import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ChartHeaderCustomized, CheckboxHeader } from '../BarChart'

const StackAreaChart = ({
    title = { text: '', subtext: '' },
    series = [],
    legends = [{}],
    labels = [],
    selectedLegends = [],
    setSelecteds = () => { },
    datasheets = [],
    objectKey = '',
    children
}) => {

    const [data, setDataSource] = useState(series?.filter((serie) => selectedLegends?.includes(serie?.value)))
    useEffect(() => {
        setDataSource(series?.filter((serie) => selectedLegends?.includes(serie?.value)))
    }, [selectedLegends, series])

    return (
        <Paper sx={{ p: 1.5 }}>
            <ChartHeaderCustomized title={title?.text} children={children} />
            <CheckboxHeader legends={legends} selections={selectedLegends} setSelection={setSelecteds} />
            <div style={{ height: '600px' }}>
                <EChart
                    options={{
                        title: [
                            {
                                left: 'center',
                                top: 'top',
                                textStyle: {
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#777'
                                },
                                subtextStyle: {
                                    fontSize: 13, color: '#777',
                                },
                                ...title
                            },
                        ],
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        legend: {
                            data: [],
                            show: false,
                            left: 'center',
                            bottom: '3%'
                        },
                        grid: { left: '3%', right: '3%', bottom: '3%', containLabel: true },
                        xAxis: {
                            type: 'category',
                            data: labels,
                            boundaryGap: false,
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: function (value) {
                                    return value + '%'
                                }
                            },
                        },
                        series: [...data?.map((item) => ({
                            ...item,
                            emphasis: {
                                focus: 'series'
                            },
                            label: {
                                show: true,
                                position: 'top'
                            },
                            areaStyle: {},
                            type: 'line',
                            stack: 'Total'
                        }))]
                    }}
                />
            </div>
        </Paper>
    )
}

export default StackAreaChart