import ProductApi from "@/apis/beta/Product/ProductApi";
import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import ReportApi from "@/apis/beta/Report/ReportApi";
import DataGridCustomize from "@/components/DataGrid";
import { CellPricing, CellStatus } from "@/components/DataGrid/Cell";
import {
  format_date_monent_js,
  format_date_short,
  format_numeral_price,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useFilters, { Filter } from "@/hooks/useFilters";
import { INVOICE_STATUS_TYPES } from "@/libs/constants";
import DataGridFilter from "@components/DataGrid/Filter";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Checkbox, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router";
import PrivateToolbar from "./PrivateToolbar";
const Incomes = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("invoice.incomes.filters", location);

  const { data: { products } } = ProductApi.ListProducts({});

  const [dates, setDates] = useState({
    gte: dayjs().startOf("month"),
    lte: dayjs().endOf("month"),
  });
  const { data: productGroups } = ProductGroupApi.List();

  const {
    data: { invoices, employees },
  } = ReportApi.InvoiceIncomes({
    query: Filter.stringify(query),
    gte: format_date_short(dates?.gte?.$d || dates?.gte),
    lte: format_date_short(dates?.lte?.$d || dates?.lte),
  });
  const initialColumns = [
    {
      field: "invoices.customer_id",
      headerName: "ID - Khách Hàng",
      width: 150,
      valueGetter: ({ row }) => row?.customer_id,
      operators: { type: "number" },
    },
    {
      field: "customer_name",
      headerName: "Khách Hàng",
      width: 250,
      valueGetter: ({ row }) =>
        row?.customer_company_name || row?.customer_name,
    },
    {
      field: "company_industry",
      headerName: "Lĩnh Vực",
      width: 180,
    },
    {
      field: "invoices.invoice_id",
      headerName: "ID - Invoice",
      valueGetter: ({ row }) => row?.invoice_id,
      width: 100,
      operators: { type: "number" },
    },
    {
      field: "invoices.invoice_status",
      headerName: "Trạng Thái",
      width: 140,
      valueGetter: ({ row }) => {
        return INVOICE_STATUS_TYPES.find((t) => t.value === row.invoice_status);
      },
      operators: {
        type: "object",
        collections: INVOICE_STATUS_TYPES,
      },
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "invoices.renewable",
      headerName: "Renewable",
      width: 100,
      valueGetter: ({ row }) => (row?.renewable ? "Gia Hạn" : "Bán Mới"),
      operators: {
        type: "object",
        collections: [
          { value: true, label: "Gia Hạn" },
          { value: false, label: "Bán Mới" },
        ],
      },
    },
    {
      field: "tw_services.employee_sale_ids",
      valueGetter: ({ row }) => row?.employee_commission_names,
      headerName: "Người Hưởng Hoa Hồng",
      width: 200,
      operators: {
        type: "object",
        operators: ["$included"],
        collections: employees?.map((e) => ({
          value: e?.employee_id,
          label: e?.employee_name,
        })),
      },
    },
    {
      field: "employee_commission_codes",
      headerName: "Mã Nhân Viên",
      width: 200,
    },
    {
      field: "invoice_created_at",
      headerName: "Ngày Tạo",
      width: 120,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "invoice_paid",
      headerName: "Ngày Thanh Toán",
      width: 140,
      renderCell: ({ value }) => (value ? format_date_short(value) : ""),
    },
    {
      field: "service_first_id",
      headerName: "Service First(SF) ID",
      width: 150,
      valueGetter: ({ row }) => {
        if (!row?.services || row?.services?.length === 0) return "";
        return row?.service_first;
      },
      renderCell: ({ value }) => value && value?.service_id,
    },
    {
      field: "service_first_name",
      headerName: "SF Name",
      width: 140,
      valueGetter: ({ row }) => {
        if (!row?.services || row?.services?.length === 0) return "";
        return row?.service_first;
      },
      renderCell: ({ value }) => value && value?.service_name,
    },
    {
      field: "service_first_at_month",
      headerName: "SF At Month",
      width: 140,
      valueGetter: ({ row }) => {
        if (!row?.services || row?.services?.length === 0) return "";
        return row?.service_first;
      },
      renderCell: ({ value }) =>
        value && format_date_monent_js(value?.service_start_date, "MM"),
    },
    {
      field: "service_first_at_year",
      headerName: "SF At Year",
      width: 140,
      valueGetter: ({ row }) => {
        if (!row?.services || row?.services?.length === 0) return "";
        return row?.service_first;
      },
      renderCell: ({ value }) =>
        value && format_date_monent_js(value?.service_start_date, "YYYY"),
    },
    {
      field: "tw_products.product_id",
      headerName: "Tên Sản Phẩm",
      width: 150,
      valueGetter: ({ row }) => row?.product_name,
      operators: {
        type: "object",
        operators: ["$in"],
        collections: products?.map((x) => ({
          value: x?.product_id,
          label: x?.product_name,
        })),
      },
    },
    {
      field: "product_groups.product_group_id",
      headerName: "Nhóm Sản Phẩm",
      valueGetter: ({ row }) => row?.product_group_name,
      width: 150,
      operators: {
        type: "object",
        operators: ["$in"],
        collections: productGroups?.map((x) => ({
          value: x?.product_group_id,
          label: x?.product_group_name,
        })),
      },
    },
    {
      field: "service_id",
      headerName: "Service ID",
      width: 90,
    },
    {
      field: "service_start_date",
      headerName: "Ngày Bắt Đầu SDDV",
      width: 150,
      renderCell: ({ value }) => value && format_date_short(value),
    },
    {
      field: "service_at_month",
      headerName: "Service Month",
      width: 150,
      valueGetter: ({ row }) => row?.service_start_date,
      renderCell: ({ value }) => value && format_date_monent_js(value, "MM"),
    },
    {
      field: "service_at_year",
      headerName: "Service Year",
      width: 150,
      valueGetter: ({ row }) => row?.service_start_date,
      renderCell: ({ value }) => value && format_date_monent_js(value, "YYYY"),
    },
    {
      field: "invoice_item_name",
      headerName: "Invoice Item Name",
      width: 150,
    },
    {
      field: "invoice_item_description",
      headerName: "Mô Tả",
      width: 150,
    },
    {
      field: "invoice_item_subtitle",
      headerName: "Invoice Item Range",
      width: 150,
    },
    {
      field: "invoice_item_value",
      headerName: "Used",
      width: 100,
    },
    {
      field: "invoice_item_unit",
      headerName: "Unit",
      width: 100,
      renderCell: ({ value }) => format_numeral_price(value),
    },
    {
      field: "invoice_item_pricing",
      headerName: "Đơn Giá Niêm Yết (Cty)",
      width: 200,
      renderCell: ({ value }) => format_numeral_price(value),
    },
    {
      field: "invoice_item_pricing_apply",
      headerName: "Đơn Giá Bán",
      width: 200,
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_item_enddate",
      headerName: "Month",
      width: 90,
      valueGetter: ({ row }) => {
        if (!row?.invoice_item_enddate) return "";
        return format_date_monent_js(row?.invoice_item_enddate, "MM");
      },
    },
    {
      field: "invoice_item_amount",
      headerName: "Invoice Item NO VAT",
      width: 180,
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_item_tax",
      headerName: "Invoice Item VAT",
      width: 150,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox size="small" checked={value} disabled />
          </Box>
        );
      },
    },
    {
      field: "invoice_item_total",
      headerName: "Invoice Item Total",
      width: 180,
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_item_month_paid",
      headerName: "Month Paid",
      width: 150,
      valueGetter: ({ row }) =>
        format_date_monent_js(row?.invoice_paid, "MM-YYYY"),
    },
    // {
    //   field: "service_is_first",
    //   headerName: "Service First",
    //   width: 130,
    //   valueGetter: ({ row }) => {
    //     if (!row?.service_first || !row?.service_id) return undefined;
    //     return row?.service_first?.service_id === row?.service_id;
    //   },
    //   renderCell: ({ value }) => {
    //     if (typeof value === "undefined") return "Không xác định";

    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Checkbox size="small" checked={value} disabled />
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "service_is_upsale",
    //   headerName: "UPSALE",
    //   width: 130,
    //   valueGetter: ({ row }) => {
    //     if (!row?.service_first || !row?.service_id) return undefined;
    //     return row?.service_first?.service_id !== row?.service_id;
    //   },
    //   renderCell: ({ value }) => {
    //     if (typeof value === "undefined") return "Không xác định";
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Checkbox size="small" checked={value} disabled />
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "appied",
      headerName: "Thời Gian Tính Phí Dịch Vụ",
      width: 250,
      valueGetter: ({ row }) => {
        if (!row?.invoice_item_startdate || !row?.invoice_item_enddate)
          return ``;
        return `${format_date_short(
          row?.invoice_item_startdate
        )} - ${format_date_short(row?.invoice_item_enddate)}`;
      },
    },
  ];
  const hiddenColumnOnLocalStorage =
    JSON.parse(localStorage.getItem("invoices.incomes.hiddenColumns")) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (hiddenColumnOnLocalStorage?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const rows = useMemo(() => {
    const uniques = _.groupBy(invoices, (row) => {
      return [row?.invoice_id];
    });
    let rows = [];
    _.each(uniques, (values, keys) => {
      const childrens = values;
      const demo = values[0];
      let invoice_item_amount = _.sumBy(
        childrens,
        (x) => x["invoice_item_amount"]
      );
      let invoice_item_total = _.sumBy(childrens, (x) => {
        if (x["invoice_item_tax"]) {
          return x["invoice_item_amount"] + x["invoice_item_amount"] * 0.1;
        }
        return x["invoice_item_amount"];
      });
      let invoice_item_tax = demo["invoice_item_tax"];
      let invoice_item_startdate = demo["invoice_item_startdate"];
      let invoice_item_enddate = demo["invoice_item_enddate"];
      if (childrens && childrens.length > 1) {
        _.forEach(childrens, (x) => {
          if (x["invoice_item_tax"] && !invoice_item_tax) {
            invoice_item_tax = true;
          }
          if (x["invoice_item_startdate"] < invoice_item_startdate) {
            invoice_item_startdate = x["invoice_item_startdate"];
          }
          if (x["invoice_item_enddate"] > invoice_item_enddate) {
            invoice_item_enddate = x["invoice_item_enddate"];
          }
        });
      }

      rows.push({
        hierarchy: [`[Invoice: ${demo?.invoice_id}]`],
        ...demo,
        invoice_item_tax,
        invoice_item_amount,
        invoice_item_total,
        invoice_item_startdate,
        invoice_item_enddate,
        invoice_item_name: "",
        invoice_item_subtotal: "",
        invoice_item_description: "",
        invoice_item_unit: "",
        invoice_item_value: "",
        invoice_item_pricing: 0,
        invoice_item_pricing_apply: 0,
      });
      childrens.forEach((children) => {
        let invoice_item_total = children.invoice_item_amount;
        if (children.invoice_item_tax) {
          invoice_item_total += invoice_item_total * 0.1;
        }
        rows.push({
          hierarchy: [
            `[Invoice: ${children?.invoice_id}]`,
            children?.invoice_item_id,
          ],
          ...children,
          invoice_item_total: invoice_item_total,
          className: "row-disabled",
        });
      });
    });
    return rows;
  }, [invoices]);

  return (
    <Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
        <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium' }}>
          Hoá Đơn & Hoa Hồng
        </Typography>
        <Paper sx={{ p: 2, width: { xs: '100%', md: 'calc(100% / 4)' } }}>
          <Typography sx={{ fontSize: 18, fontWeight: 'medium' }} color="text.grey">
            Tổng Hoá Đơn
          </Typography>
          <Typography sx={{ fontSize: 24, fontWeight: 'medium' }}>
            {format_numeral_price(_.sumBy(invoices, (invoice) => invoice?.invoice_total))}
          </Typography>
        </Paper>
      </Box>
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "invoice.incomes.filters",
          filterables: [...filterables],
        }}
      />
      <DataGridCustomize
        treeData
        getTreeDataPath={(row) => row?.hierarchy}
        rows={rows.map((x, index) => ({ ...x, id: index }))}
        columns={columns}
        componentsProps={{
          toolbar: {
            store: "invoice.incomes.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            dates: dates,
            onChangeDates: setDates,
            // inputSearch: {
            //   placeholder:
            //     "Nhập ID, số hoá đơn/ tên khách hàng/ ngày kết thúc dịch vụ tìm kiếm...",
            //   value: searchQuery,
            //   onChange: setSearchQuery,
            // },
          },
        }}
        components={{
          Toolbar: PrivateToolbar,
          Pagination: "disabled",
        }}
        getRowClassName={({ row }) => {
          return clsx(`grid-dc-vnetwork`, row?.className);
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Incomes, {
    feature: "report",
    action: "invoice-incomes",
  }),
  MuiSkeleton["DataGrid"]
);
