import React, { useMemo } from 'react'
import StackChart from '../../../Charts/StackChart'
import { Categories } from '../../functions'
import { Grid } from '@mui/material'
import _ from 'lodash'
import YearlyInformation from './Information'

const options = {
    type: 'bar',
    barGap: 0,
    emphasis: { focus: 'series' },
    label: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{a}',
        // formatter: '{c}  {name|{a}}',
        fontSize: 13,
        rich: {
            name: {}
        }
    },
    animationDuration: 800, // Thời gian chuyển đổi 800ms
    animationEasing: 'cubicOut',
}
const YearReportChart = ({ datasheets = [] }) => {

    const years = useMemo(() => {
        return _.uniqBy(datasheets?.map((month) => month.year), (num) => parseInt(num))?.sort((a, b) => a - b)
    }, [datasheets])
    return (
        <StackChart options={{
            tooltip: { trigger: 'axis', axisPointer: { type: 'cross' } },
            title: { text: `Báo Cáo Kết Quả Kinh Doanh`, left: 'center' },
            xAxis: [
                {
                    type: 'category', axisTick: { show: false },
                    data: [...years]
                }
            ],
            toolbox: {
                show: true,
                orient: 'vertical',
                left: 'right',
                top: 'center',
            },
            yAxis: [{ type: 'value' }],
            legend: {
                data: Categories?.map(cate => cate?.label),
                top: 'bottom',
                left: 'center',
            },
        }}
            data={Categories?.map((category) => {
                return {
                    name: category?.label,
                    ...options,
                    data: [...years]?.map((year) => _.sumBy(datasheets?.filter(datasheet => datasheet.year === year), (record) => {
                        return record?.[`${category.value}`]
                    }))
                }
            })}
        >
            <Grid item xs={12} md={4}>
                <YearlyInformation datasheets={datasheets} />
            </Grid>
        </StackChart>
    )
}

export default YearReportChart