import { format_numeral_price, formatPriceUnit } from '@/functions'
import EChart, { SCHEMES } from '@components/EChart'
import { Box, Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'


export const CheckboxHeader = ({ legends = [], selections = [], setSelection = () => { } }) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1.25 }}>
            <FormControlLabel
                label="Chọn tất cả"
                control={<Checkbox size='small'
                    indeterminate={selections?.length !== 0 && selections?.length !== legends?.length}
                    onChange={(event) => {
                        if (event.target.checked) {
                            setSelection(legends?.map((legend) => legend?.value))
                        } else {
                            setSelection([])
                        }
                    }}
                    d
                    checked={selections?.length === legends?.length}
                />}
            />
            {[...legends]?.map((legend, index) => {
                return (
                    <FormControlLabel
                        key={index}
                        label={legend?.label}
                        control={<Checkbox size='small'
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setSelection(pre => [...pre, legend?.value])
                                } else {
                                    setSelection(pre => [...pre?.filter(x => x !== legend?.value)])
                                }
                            }}
                            checked={selections?.includes(legend?.value)}
                            sx={{
                                color: SCHEMES?.default[index],
                                ".MuiSvgIcon-root": {
                                    color: SCHEMES?.default[index]
                                }
                            }}
                        />}
                    />
                )
            })}
        </Box>
    )
}

export const ChartHeaderCustomized = ({
    title = 'Báo Cáo Dữ Liệu',
    chartOptions = () => { return <></> },
    children
}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{
                display: 'flex', flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: { xs: 1.25, md: 1.5 }
            }}>
                <Typography sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: 'medium' }}>
                    {title}
                </Typography>
                {children}
            </Box>
            {chartOptions}
        </Box>
    )
}

const quarters = ["I", "II", "III", "IV"]
const Statistical = ({ datasheets = [], objectKey = '' }) => {
    const years = useMemo(() => {
        return _.uniq(datasheets?.map((datasheet) => datasheet?.year)).sort((a, b) => a - b)
    }, [datasheets])

    return (
        <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontWeight: 'medium', textTransform: 'capitalize' }}>
                Số liệu thống kê theo tháng
            </Typography>
            {years?.map((year) => {
                return (
                    <Grid container key={year}>
                        {years?.length > 1 && <Grid item xs={12}>
                            <Box sx={{ display: 'flex', p: '4px 0', bgcolor: '#7776801F', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>Năm {year}</Typography>
                            </Box>
                        </Grid>}
                        {Array.from({ length: 4 }).map((__, quarter) => {
                            return (
                                <Grid item xs={12} md={6} key={quarter}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: '4px 0' }}>
                                        <Typography sx={{ fontWeight: 'medium', fontSize: 14 }}>
                                            Quý {quarters[quarter]}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        {Array.from({ length: 3 })?.map((__, month) => {
                                            const execMonth = quarter * 3 + month + 1

                                            const value = _.sumBy(datasheets, (datasheet) => {
                                                if (datasheet?.month === execMonth && datasheet?.year === year) {
                                                    return datasheet?.[`${objectKey}`]
                                                }
                                                return 0
                                            })
                                            return (
                                                <Box key={month} sx={{ width: '100%' }}>
                                                    <Box sx={{
                                                        display: 'flex', justifyContent: 'center',
                                                        bgcolor: (quarter === 1 || quarter === 2) ? '#1a237e' : '#A5A8C7',
                                                        p: '4px 0'
                                                    }}>
                                                        <Typography sx={{ fontSize: 13, fontWeight: 'medium', color: '#FFF' }}>
                                                            Tháng {execMonth}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ justifyContent: 'center', p: '4px 0', display: 'flex' }}>
                                                        <Typography sx={{ fontSize: 13, fontWeight: 'medium', textAlign: 'center' }}>
                                                            {format_numeral_price(value)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>)
            })
            }
        </Box>
    )
}

const BarChart = ({
    title = { text: '', subtext: '' },
    series = [],
    legends = [{}],
    labels = [],
    selectedLegends = [],
    setSelecteds = () => { },
    datasheets = [],
    objectKey = '',
    children
}) => {

    const [data, setDataSource] = useState(series?.filter((serie) => selectedLegends?.includes(serie?.value)))
    useEffect(() => {
        setDataSource(series?.filter((serie) => selectedLegends?.includes(serie?.value)))
    }, [selectedLegends, series])

    //
    return (
        <Paper sx={{ p: 1.5 }}>
            <ChartHeaderCustomized title={title?.text} children={children} />
            <CheckboxHeader legends={legends} selections={selectedLegends} setSelection={setSelecteds} />
            <div style={{ height: '600px' }}>
                <EChart
                    options={{
                        title: [
                            {
                                left: 'center',
                                top: 'top',
                                textStyle: {
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#777'
                                },
                                subtextStyle: {
                                    fontSize: 13, color: '#777',
                                },
                                ...title
                            },
                        ],
                        tooltip: { trigger: 'axis' },
                        legend: {
                            data: [],
                            show: false,
                            left: 'center',
                            bottom: '3%'
                        },
                        grid: { left: '3%', right: '3%', bottom: '3%', containLabel: true },
                        xAxis: {
                            type: 'category',
                            data: labels
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: function (value) {
                                    return formatPriceUnit(value)
                                }
                            },
                        },
                        series: [...data?.map((item) => ({
                            ...item,
                            emphasis: {},
                            type: 'bar',
                        }))]
                    }}
                />
            </div>
            <Statistical datasheets={datasheets} objectKey={objectKey} />
        </Paper>
    )
}

export default BarChart