import { requestWithToken, encodeBase64, payloadToQueries } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_BETA_URL, API_V3_URL } from "@/libs/constants";
import _ from "lodash";

class ReportApi {
	InvoiceIncomes = ({ query, gte, lte, searchQuery }) => {
		return useQueryWithCustomized(
			["reports.invoice.incomes", query, gte, lte],
			async () => {
				return await requestWithToken(
					`${API_V3_URL}/reports/invoices/incomes?gte=${gte}&lte=${lte}${!!query && `&filters=${encodeBase64(query)}`
					}`
				)
					.then(({ data }) => {
						if (data?.errors) return data;

						const invoices = data?.data?.invoices;
						return {
							invoices: invoices,
							employees: data?.data?.employees || [],
							pagination: data?.data?.pagination || {},
						};
					})
					.catch((error) => error.response);
			},
			{
				suspense: true,
				staleTime: 10 * 60 * 1000,
			}
		);
	};
	MonthlyRenewable = (month) =>
		useQueryWithCustomized(
			["reports.MonthlyRenewable", month],
			async () => {
				return await requestWithToken(
					`${API_BETA_URL}/reports/templates/incomes`
				)
					.then(({ data }) => {
						if (data?.errors) return data;

						let rows = _.flatten(data?.data?.quaters);
						rows = _.map(data?.data?.quaters, (item, index) => {
							return {
								id: index,
								hierarchy: [item.quarter, item.month, item.name],
								...item,
							};
						});

						return {
							quaters: data?.data?.quaters
								? data?.data?.quaters.map((invoice, index) => ({
									...invoice,
									id: index,
								}))
								: [],
							rows: rows,
						};
					})
					.catch((error) => error.response);
			},
			{
				staleTime: 10 * 60 * 1000,
				suspense: true,
			}
		);

	MonthlyServiceRenewable = (month) =>
		useQueryWithCustomized(
			["reports.MonthlyServiceRenewable", month],
			async () => {
				return await requestWithToken(
					`${API_BETA_URL}/reports/templates/services`
				)
					.then(({ data }) => {
						if (data?.errors) return data;

						let rows = _.flatten(data?.data?.quaters);

						rows = _.map(data?.data?.quaters, (item, index) => {
							return {
								id: index,
								hierarchy: [item.quarter, item.month, item.name],
								...item,
							};
						});

						return {
							quaters: data?.data?.quaters
								? data?.data?.quaters.map((service, index) => ({
									...service,
									id: index,
								}))
								: [],
							rows: rows,
						};
					})
					.catch((error) => error.response);
			},
			{
				staleTime: 10 * 60 * 1000,
				suspense: true,
			}
		);

	Finance = ({ query }) => {
		const queries = payloadToQueries(query)
		return useQueryWithCustomized(
			["reports.finance.invoices", queries],

			async () => {
				return await requestWithToken(
					`${API_V3_URL}/reports/finance/invoices?${queries}`
				)
					.then(({ data }) => {
						if (data?.errors) return data;

						return data?.data
					})
					.catch((error) => error.response);
			},
			{
				suspense: true,
				staleTime: 10 * 60 * 1000,
			}
		);
	};

	InvoiceExport = async ({ query }) => {
		const queries = payloadToQueries(query)
		console.log(queries)
		const response = await requestWithToken(
			`${API_V3_URL}/reports/finance/invoices?${queries}`,
			"GET",
			{},
			{
				"Content-Type": "application/vnd.ms-excel",
				"CONTENT-DISPOSITION": "attachment",
			},
			{ withCredentials: true, responseType: "blob" }
		);
		return response?.data;
	};

	//

	BusinessPerformance = ({ query }) => {
		const queries = payloadToQueries(query);
		return useQueryWithCustomized(
			["reports.finance.business.performance", queries],
			async () => {
				return await requestWithToken(
					`${API_V3_URL}/reports/finance/business/performance?${queries}`
				)
					.then(({ data }) => {
						if (data?.errors) return data;

						return {
							monthly: data?.data?.monthly || []
						}
					})
					.catch((error) => error.response);
			},
			{
				suspense: true,
				staleTime: 10 * 60 * 1000,
			}
		);
	};

	CreateBusinessPerformance = async ({ data = [] }) => {
		await requestWithToken(
			`${API_V3_URL}/reports/finance/business/performance`,
			"POST",
			{
				finance: { business_performances: data }
			},
		).then(({ data }) => {
			if (data?.error || data?.errors) return data;
			return data;
		}).catch((err) => {
			return err
		})
	};
	BusinessPerformanceSampleFile = async () => {
		const response = await requestWithToken(
			`${API_V3_URL}/reports/finance/sample`,
			"GET",
			{},
			{
				"Content-Type": "application/vnd.ms-excel",
				"CONTENT-DISPOSITION": "attachment",
			},
			{ withCredentials: true, responseType: "blob" }
		);
		return response?.data;
	};

	Import = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		const response = await requestWithToken(
			`${API_V3_URL}/reports/finance/business/performance/import`,
			"POST",
			formData
		);

		return response?.data;
	};

	BalanceSheets = () => {
		return useQueryWithCustomized(
			["reports.finance.balance.sheets"],
			async () => {
				return await requestWithToken(
					`${API_V3_URL}/reports/finance/balance`
				)
					.then(({ data }) => {
						if (data?.errors) return data;

						return data?.data?.balance_sheets || []
					})
					.catch((error) => error.response);
			},
			{
				suspense: true,
				staleTime: 10 * 60 * 1000,
			}
		);
	};


}
export const rowItemsResolver = (rows, key, rowNode) =>
	rows?.filter((item) => item[key] === rowNode.groupingKey);

export default ReportApi = new ReportApi();
