import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";

import { first, indexOf, last } from "lodash";

import {
  BarChart,
  LineChart,
  PieChart,
} from "echarts/charts";

import {
  // GridSimpleComponent,
  GridComponent,
  LegendComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  MarkAreaComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  ToolboxComponent,
  TooltipComponent,
} from "echarts/components";

import { amber, blue, deepOrange, indigo, lightGreen, orange, purple } from "@mui/material/colors";
import { SVGRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  MarkAreaComponent,
  SVGRenderer,
  LineChart,
  BarChart,
  PieChart,
  LegendComponent,
  ToolboxComponent
]);

export const SCHEMES = {
  default: [
    "#1f77b4", // – Blue
    "#ff7f0e", // – Orange
    "#2ca02c", // – Green
    "#d62728", // – Red
    "#9467bd", // – Purple
    "#8c564b", // – Brown
    "#e377c2", // – Pink
    "#7f7f7f", // – Gray
    "#bcbd22", // – Olive
    "#17becf", // – Teal
    "#1a55b2", // – Dark Blue
    "#ffbb78", // – Light Orange
    "#98df8a", // – Light Green
    "#ff9896", // – Light Red
    "#c5b0d5", // – Lavender
    "#c49c94", // – Light Brown
    "#f7b6d2", // – Light Pink
    "#c7c7c7", // – Light Gray
    "#dbdb8d", // – Pale Olive
    "#9edae5", // – Light Teal
  ],
  blue: [
    "#4A90E2", // Xanh dương
    "#50C2C9", // Xanh ngọc nhạt
    "#F5A623", // Cam nhạt
    "#7ED321", // Xanh lá
    "#D0021B", // Đỏ tươi
    "#B8E986", // Xanh lá mạ nhạt
    "#F8E71C", // Vàng
    "#BD10E0", // Tím nhạt
    "#9013FE", // Tím đậm
    "#FF7F66"  // Hồng cam nhạt
  ],
  primary: ["#1a73e8", "#4747EB", "#7333CC", "#720796", "#0878B3", "#da8b45", "#d5bf57", "#327a42", "#69707d", "#f4a701"],
  material: [orange[200], amber[200], purple[200], lightGreen[200], indigo[200], blue[200], deepOrange[200], orange[400], amber[400], purple[400], lightGreen[400], indigo[400], blue[400], deepOrange[400], orange[600], amber[600], purple[600], lightGreen[600], indigo[600], blue[600], deepOrange[600]],
  secondary: ["#a9d9cc", "#b0c8df", "#e9afc2", "#c8b8db", "#e4c6d6", "#ecc5a4", "#eae0ac", "#99bca0", "#b4b7be", "#f9d48e"],
  mono: ["#434be7", "#7f98fa", "#a4bbfd", "#c5d7fe", "#dfe9ff"],
  gray: "#e1e1e1",
  black: "#000333",
  backgroundOpacity: "rgba(255,255,255,0.9)",
  gradient: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: "#dfe9ff",
    },
    {
      offset: 1,
      color: "rgba(255,255,255,1)",
    },
  ]),
};

export const TOOLTIP_OPTIONS = {
  showDelay: 0,
  hideDelay: 50,
  transitionDuration: 0,
  backgroundColor: SCHEMES.backgroundOpacity,
  borderColor: SCHEMES.black,
  borderRadius: 0,
  borderWidth: 1,
  padding: [8, 8, 8, 8],
  textStyle: {
    color: SCHEMES.black,
    decoration: "none",
    fontSize: 13,
  },
  extraCssText: 'box-shadow: none; font-family: "Google Sans",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
};

export const useMarkArea = (array = [], arrayOfZero = []) => {
  let markAreaStart,
    markAreaStop;

  if (arrayOfZero.length !== 0) {
    markAreaStart = first(arrayOfZero)
    markAreaStop = last(arrayOfZero)

    const indexOfFirstDayGotZeroTimestamp = indexOf(array, markAreaStart)

    markAreaStart = array[indexOfFirstDayGotZeroTimestamp - 1]
  }

  return [markAreaStart, markAreaStop]
}

const EChart = ({ options, ...props }) => {
  return options ? (
    <ReactEChartsCore echarts={echarts} option={options} {...props} key={JSON.stringify(options?.series)} />
  ) : null;
};

export default EChart;
