import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import CustomerContractIcon from "@mui/icons-material/DocumentScannerOutlined";
import CustomerJourneyIcon from "@mui/icons-material/PendingActionsOutlined";
import CustomerInformationIcon from "@mui/icons-material/PermContactCalendarOutlined";
import CustomerFinanceIcon from "@mui/icons-material/RequestQuoteOutlined";
import { Box, Typography } from "@mui/material";

const CustomerTabs = ({ children }) => {
    const initialTabs = [
        {
            value: "",
            label: "Kết Quả Kinh Doanh",
            href: "business_performance",
            icon: CustomerInformationIcon,
        },
        {
            value: "customer-journey",
            label: "Cân Đối Kế Toán",
            href: "balance",
            icon: CustomerJourneyIcon,
        },
        {
            value: "profitabilities",
            label: "Khả Năng Sinh Lợi",
            href: "profitabilities",
            icon: CustomerContractIcon,
        },
        {
            value: "financial",
            label: "Sức Mạnh Tài Chính",
            href: "financial",
            icon: CustomerFinanceIcon,
        },
        {
            value: "effeciency",
            label: "Hiệu Quả Quản Lý",
            href: "effeciency",
        },
        {
            value: "operation",
            label: "Khả Năng Hoạt Động",
            href: "operation",
        }
    ];

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: { xs: 18, md: 20 }, fontWeight: "medium" }}>
                    Báo Cáo Tài Chính
                </Typography>
            </Box>
            <MuiScrollTabs
                prefix={`reports/finance`}
                initialTabs={initialTabs}
            />
            {children}
        </Box>
    );
};

export default withSuspense(CustomerTabs, MuiSkeleton["ScrollTabs"]);
