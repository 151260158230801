import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import { useLocation, useSearchParams } from "react-router-dom";
import useFilters from "./useFilters";

const useDataGridParams = (initialColumns, pageSize = 20, key) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("page_size")) || pageSize,
  });
  const [columns, setColumns] = useState(initialColumns);
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const disableSearchPageParams = false;

  const location = useLocation();
  const [query, updateQuery] = useFilters(key, location);

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set("page", page.page);
      prev.set("page_size", page.pageSize);
      prev.set("q", searchQuery);
      return prev;
    });
  }, [page.page, page.pageSize, searchQuery]);

  return {
    page,
    setPage,
    columns,
    setColumns,
    searchQuery,
    setSearchQuery,
    debouncedSearchQuery,
    disableSearchPageParams,
    query,
    updateQuery,
  };
};

export default useDataGridParams;
