import ReportApi from "@/apis/beta/Report/ReportApi";
import { downloadFileWithBinaryResponse } from "@/functions";
import { FileOpenOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useMutation } from "react-query";

const BusinessPerformanceSampleFile = ({
    disabled = false
}) => {
    const { mutateAsync, isLoading } = useMutation(ReportApi.BusinessPerformanceSampleFile);

    const handler = async () => {
        const response = await mutateAsync();
        if (!response) return;
        downloadFileWithBinaryResponse(`business_performance_sample_file.xlsx`, response);
    };

    return (
        <Button
            startIcon={
                !isLoading ? <FileOpenOutlined /> : <CircularProgress size={18} />
            }
            disabled={isLoading || disabled}
            onClick={handler}
            variant='outlined'
            size="small"
        >
            Tải File Mẫu
        </Button>
    );
};

export default BusinessPerformanceSampleFile
