import { format_numeral_price } from '@/functions';
import EChart from '@components/EChart';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';

const StackNormalization = ({
    mode = { data: 'bar', timeline: 'monthly' },
    data = {}
}) => {
    const buildSeries = useMemo(() => {
        return data?.services?.map(service => {
            return {
                emphasis: {
                    focus: 'series'
                },
                name: !service ? 'Khác' : service?.toUpperCase(),
                type: mode?.data,
                stack: 'Total',
                data: mode?.timeline === 'monthly' ? [...Array.from({ length: 12 })?.map((__, i) => {
                    return data?.invoices.reduce((total, invoice) => {
                        if (invoice?.service_group_code === service && invoice?.month === i + 1) {
                            return total + invoice?.total
                        }
                        return total
                    }, 0)

                })] : [...Array.from({ length: 4 })?.map((__, i) => {
                    return data?.invoices.reduce((total, invoice) => {
                        if (invoice?.service_group_code === service && Math.ceil(invoice?.month / 3) === i + 1) {
                            return total + invoice?.total
                        }
                        return total
                    }, 0)

                })]
            }
        })
    }, [data?.invoices, data?.services, mode])
    const options = {
        title: {
            text: `Tổng Doanh Thu Theo ${mode?.timeline === 'monthly' ? 'Tháng' : 'Quý'}`,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                let tooltipText = `${params[0].axisValue}<br/>`;
                params.forEach((item) => {
                    const currentValue = item.data;
                    const prevValue =
                        item.dataIndex > 0 ? options.series[item.seriesIndex].data[item.dataIndex - 1] : 0;

                    let changeText = '';
                    if (prevValue !== 0) {
                        const change = ((currentValue - prevValue) / prevValue) * 100;
                        changeText = ` (${change > 0 ? '+' : ''}${format_numeral_price(change.toFixed(2), true)}%)`;
                    }

                    tooltipText += `${item.marker} ${item.seriesName}: ${format_numeral_price(currentValue)} ${changeText}<br/>`;
                });

                return tooltipText;
            },
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {
            left: 'center',
            top: 'bottom',
            data: [...data?.services?.map(x => !x ? 'Khác' : x?.toUpperCase())]
        },
        grid: {
            left: '4%',
            right: '4%',
            bottom: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: mode?.data === 'bar',
            data: [...Array.from({ length: mode?.timeline === 'monthly' ? 12 : 4 }).map((__, i) => i + 1)?.map(month => { return (mode?.timeline === 'monthly' ? 'Tháng' : 'Quý') + ` ${month}` })]
        },
        yAxis: {
            type: 'value'
        },
        series: buildSeries
    };
    const totalPrice = useMemo(() => {
        return _.sumBy(data?.invoices, (invoice) => invoice?.total)
    }, [data?.invoices])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: 'medium', mb: 1.25 }}>
                            Số Liệu Thống Kê Theo {mode?.timeline === 'monthly' ? 'Tháng' : 'Quý'}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5 }}>
                            {[...Array.from({ length: mode?.timeline === 'monthly' ? 12 : 4 })?.map((__, i) => {
                                const total = data?.invoices.reduce((total, invoice) => {
                                    if ((mode?.timeline === 'monthly' ? invoice?.month : Math.ceil(invoice.month / 3)) === i + 1) {
                                        return total + invoice?.total
                                    }
                                    return total
                                }, 0)

                                return (
                                    <Box key={i} sx={{ width: { xs: '100%', md: 'calc((100% - 12px * 11) / 12)' } }}>
                                        <Typography component="div" sx={{ fontSize: 13, fontWeight: 'medium' }}>
                                            {mode?.timeline === 'monthly' ? `Tháng ${i + 1}` : `Quý ${i + 1}`}
                                        </Typography>
                                        <Typography component="span" sx={{ fontSize: 13, fontWeight: 'medium', color: 'text.grey' }}>
                                            {format_numeral_price(total)}
                                        </Typography>
                                    </Box>
                                )
                            })]}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: 'medium', }}>
                            Số Liệu Thống Kê Theo Dịch Vụ
                        </Typography>
                        <Typography sx={{ fontSize: 14, mb: 1.25 }}>
                            Tổng Doanh Thu: {format_numeral_price(totalPrice)}
                        </Typography>
                        {data?.services?.map((service, index) => {
                            const totalAmount = _.sumBy(data?.invoices?.filter(invoice => invoice?.service_group_code === service), (invoice) => invoice?.total)
                            return (
                                <Typography key={index} sx={{ display: 'flex', pb: 1.25, fontSize: 13 }} >
                                    <Typography sx={{ display: 'inline-flex', fontSize: 13, fontWeight: 'medium', minWidth: 160 }}>
                                        {!!service ? (service) : 'Khác'}
                                    </Typography>
                                    <Typography component="span" sx={{ fontSize: 13 }} color="text.grey">:{" "}</Typography>
                                    <Typography sx={{ fontSize: 13, width: 120, float: 'right', textAlign: 'right' }}>
                                        {format_numeral_price(totalAmount)}
                                    </Typography>

                                    <Typography sx={{ fontSize: 13, width: 120, float: 'right', textAlign: 'right' }} color="text.grey">
                                        Tỉ trọng: {format_numeral_price(Math.round((totalAmount * 10000 / totalPrice)) / 100, true)}%
                                    </Typography>
                                </Typography>
                            )
                        })}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={9}>
                <div style={{ height: '700px' }}>
                    <EChart options={options} />
                </div>
            </Grid>
        </Grid>
    );
}

export default StackNormalization;
