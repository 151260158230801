import React, { useMemo } from 'react'
import StackChart from '../../../Charts/StackChart'
import { Categories } from '../../functions'
import { Grid } from '@mui/material'
import _ from 'lodash'
import QuarterInformation from './Information'

const options = {
    type: 'bar',
    barGap: 0,
    emphasis: { focus: 'series' },
    animationDuration: 800, // Thời gian chuyển đổi 800ms
    animationEasing: 'cubicOut',
}
const QuarterReportChart = ({ datasheets = [], state = { chartMode: {} } }) => {
    const categories = useMemo(() => {
        let items = []
        _.uniqBy(datasheets?.map((month) => month.year), (num) => parseInt(num))?.sort((a, b) => a - b)?.forEach((year) => {
            [1, 2, 3, 4]?.forEach((quarter) => {
                items.push({ label: `Quý ${quarter} năm ${year}`, year, quarter })
            })
        })
        return items
    }, [datasheets])
    return (
        <StackChart options={{
            tooltip: { trigger: 'axis', axisPointer: { type: 'cross' } },
            title: {
                text: `Báo Cáo Kết Quả Kinh Doanh`, left: 'center',
                textStyle: {
                    fontSize: 14,
                    color: "#777680",
                    fontWeight: '500'
                }
            },
            xAxis: [
                {
                    type: 'category', axisTick: { show: false },
                    data: [...categories?.map((category) => category?.label)]
                }
            ],
            toolbox: {
                show: true,
                orient: 'vertical',
                left: 'right',
                top: 'center',
            },
            yAxis: [{ type: 'value' }],
            legend: {
                data: Categories?.map(cate => cate?.label),
                top: 'bottom',
                left: 'center',
            },
        }}
            data={Categories?.map((category) => {
                return {
                    name: category?.label,
                    ...options,
                    data: [...categories]?.map((cate) => _.sumBy(datasheets?.filter(datasheet =>
                        datasheet.year === cate?.year && cate?.quarter === Math.ceil(datasheet.month / 3)
                    ), (record) => {
                        return record?.[`${category.value}`]
                    }))
                }
            })}
        // handleBarClick={handleBarClick}
        >
            <Grid item xs={12} md={4}>
                <QuarterInformation datasheets={datasheets} />
            </Grid>
        </StackChart>
    )
}

export default QuarterReportChart