import BalanceSheetApi from '@/apis/beta/Report/BalanceSheetApi'
import { useNotificationContext } from '@contexts/notification'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import { Button } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'

const Create = () => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const { mutateAsync, isLoading } = useMutation(BalanceSheetApi.Export)

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            data: values
        })

        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message || 'Failed', severity: 'error' })
        }
        queryClient.refetchQueries('')

        return setNotification({ open: true, message: 'Tạo thành công', severity: 'success' })
    }

    return (
        <>
            <Button size='small' variant='contained' startIcon={<FileDownloadOutlined />}
                onClick={onSubmit}
                disabled={isLoading}
            >
                Tải Báo Cáo
            </Button>
        </>

    )
}

export default Create