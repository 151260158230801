import { format_date_short, format_number_usage } from '@/functions'
import DataGridCustomize from '@components/DataGrid'
import { CellLink } from '@components/DataGrid/Cell'
import CellPricing from '@components/DataGrid/Cell/FormatterPricing'
import { useState } from 'react'

const FinanceInvoice = ({ data = [], isFetching }) => {
    const [page, setPage] = useState({
        page: 0,
        pageSize: 25
    })
    const [columns] = useState([
        {
            field: 'invoice_date',
            minWidth: 120,
            headerName: 'Ngày IVN/ BILL',
            valueGetter: ({ row }) => format_date_short(row?.invoice_date)
        },
        {
            field: 'quarterly', headerName: 'Quý',
            valueGetter: ({ row }) => {
                return `Quý ${Math.ceil(row?.month / 3)}`
            }
        },
        { field: 'month', headerName: 'Tháng' },
        { field: 'year', headerName: 'Năm' },
        {
            field: 'invoice_number', headerName: 'Số IVN',
        },
        {
            field: 'invoice_id', headerName: 'Mã DC',
            valueGetter: ({ row }) => {
                return {
                    label: row?.invoice_id,
                    href: ['invoices', row?.invoice_id]
                }
            },
            renderCell: ({ value }) => <CellLink data={value} />
        },
        {
            field: 'company_name', headerName: 'Tên Khách Hàng', minWidth: 250,
            valueGetter: ({ row }) => {
                if (row?.company_name && !!row?.company_name) {
                    return row?.company_name
                }
                return row?.customer_name
            }
        },
        { field: 'invoice_detail', headerName: 'Chi Tiết Dịch Vụ', minWidth: 300 },
        {
            field: 'unit', headerName: 'Đơn Vị Tính', minWidth: 120,
            valueGetter: ({ row }) => {
                const objects = {
                    'year': 'Năm',
                    'pack/year': 'Năm',
                    'month': 'Tháng',
                    'pack/month': 'Tháng',
                    'pack/ month': 'Tháng',
                    'package': 'Tháng',
                    'package/month': 'Tháng',
                    'qty': 'Số Lượng',
                    'gb': 'GB',
                    'hour': 'Giờ',
                    'hours': 'Giờ',
                    'hour(s)': 'Giờ',
                    'stream': 'Luồng'
                }
                return objects[row?.unit?.toLowerCase()] ?? row?.unit
            }
        },
        {
            field: 'value',
            headerName: 'Số Lượng',
            minWidth: 120,
            renderCell: ({ value }) => format_number_usage(value)
        },
        {
            field: 'price',
            headerName: 'Đơn Giá',
            minWidth: 150,
            renderCell: ({ value }) => <CellPricing price={value} />
        },
        {
            field: 'total',
            headerName: 'Thành Tiền/ Doanh Số',
            minWidth: 180,
            renderCell: ({ value }) => <CellPricing price={value} />
        },
        {
            field: 'service_id', headerName: 'Mã Dịch Vụ', minWidth: 150,
            valueGetter: ({ row }) => {
                return {
                    label: row?.invoice_id,
                    href: ['invoices', row?.invoice_id]
                }
            },
            renderCell: ({ value }) => <CellLink data={value} />
        },
    ])

    return (
        <DataGridCustomize
            loading={isFetching}
            columns={columns}
            rows={data?.map((row, index) => ({ ...row, id: index }))?.slice(page?.page * page?.pageSize, (page?.page + 1) * page?.pageSize)}
            components={{
                Toolbar: 'disabled',
            }}
            componentsProps={{
                pagination: {
                    page: page?.page,
                    pageSize: page?.pageSize,
                    rowsPerPageOptions: [25, 50, 100, 250, 500],
                    onPageChange: (newPage) =>
                        setPage((prevState) => ({ ...prevState, page: newPage })),
                    onPageSizeChange: (newPageSize) =>
                        setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
                },
            }}
            rowCount={data?.length}
            paginationMode="client"
        />
    )
}

export default FinanceInvoice