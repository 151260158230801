import EChart from '@components/EChart'
import { Box, Button, ButtonGroup, Grid, Paper } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { Categories } from '../../functions'


const QuarterRanges = [
    { value: 'quarter1,quarter2,quarter3,quarter4', label: 'Xem toàn bộ', sx: { width: 120 } },
    { value: 'quarter1', label: 'Quý 1' },
    { value: 'quarter2', label: 'Quý 2' },
    { value: 'quarter3', label: 'Quý 3' },
    { value: 'quarter4', label: 'Quý 4' },
]
const QuarterComparetableChart = ({ datasheets = [] }) => {
    const [quarterMode, setQuarter] = useState('quarter1,quarter2,quarter3,quarter4')
    return (
        <Box sx={{}}>
            <ButtonGroup sx={{ mb: 2 }}>
                {QuarterRanges?.map((quarter, index) => {
                    return (
                        <Button size='small'
                            key={index}
                            variant={quarter?.value === quarterMode ? 'contained' : 'outlined'}
                            onClick={() => setQuarter(quarter?.value)}
                            sx={{
                                width: 80,
                                ...quarter?.sx
                            }}
                        >
                            {quarter?.label}
                        </Button>
                    )
                })}
            </ButtonGroup>
            <Grid container spacing={2}>
                {QuarterRanges?.slice(1)?.map((quarterRange, index) => {

                    const hidden = quarterMode === 'quarter1,quarter2,quarter3,quarter4' ? false : quarterRange?.value !== quarterMode
                    if (!!hidden) return null
                    return (
                        <Grid key={index} item xs={12} md={quarterMode === 'quarter1,quarter2,quarter3,quarter4' ? 6 : 12}>
                            <Paper sx={{ p: 1.25, height: '400px' }}>
                                <EChart options={{
                                    tooltip: { trigger: 'axis', axisPointer: { type: 'cross' } },
                                    title: { text: quarterRange?.label, left: 'center', textStyle: { fontSize: 14, color: "#777680" } },
                                    xAxis: [
                                        {
                                            type: 'category', axisTick: { show: false },
                                            data: Categories?.map((cate) => cate?.label)
                                        }
                                    ],
                                    yAxis: [{ type: 'value' }],
                                    legend: { data: Categories, top: 'bottom', left: 'center' },
                                    series:
                                        _.sortedUniqBy(_.uniqBy(datasheets?.map((month) => month.year), (num) => parseInt(num)))?.map((year) => {
                                            return ({
                                                name: year,
                                                type: 'bar',
                                                data: Categories?.map((category) => {
                                                    return _.sumBy(datasheets?.filter(datasheet =>
                                                        Math.ceil(datasheet?.month / 3) === index + 1 && datasheet?.year === year), (record) => {
                                                            return record?.[`${category.value}`]
                                                        })
                                                })
                                            })
                                        })

                                }} />
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default QuarterComparetableChart