import DatePicker from '@components/datepickers/DateRangPicker'
import { Box, FormControl } from '@mui/material'
import dayjs from 'dayjs'
import BalanceActions from '../../Actions'
const BalanceTimes = ({ queries = {
    charts: {
        current: { year: undefined },
        compare: { year: undefined },
    }
}, callbackQueries = () => { } }) => {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
            <FormControl fullWidth>
                <DatePicker value={[dayjs(queries?.gte), dayjs(queries?.lte)]} handler={(newValues) => {
                    callbackQueries({ ...queries, gte: newValues[0], lte: newValues[1] });
                }} />
            </FormControl>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                <BalanceActions.Create />
                <BalanceActions.Export />
            </Box>
        </Box>
    )
}

export default BalanceTimes