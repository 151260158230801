import { Box, Typography } from '@mui/material'

const MonthInformation = ({ datasheets = [] }) => {
    return (
        <Box sx={{ width: '100%', height: '100%', p: 1.5, border: '1px solid #333', borderRadius: '4px' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'medium', mb: 1.25 }}>
                Thông tin kết quả kinh doanh theo Quý
            </Typography>
        </Box>
    )
}

export default MonthInformation