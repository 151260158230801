import ReportApi from '@/apis/beta/Report/ReportApi'
import { format_date_short } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import Create from './Actions/Create'
import Export from './Actions/Export'
import Mode from './Actions/Mode'
import BusinessPerformanceSampleFile from "./Actions/Sample"
import Import from './Actions/Upload'
import MonthComparetableChart from './ComparetableCharts/Month'
import QuarterComparetableChart from './ComparetableCharts/Quarter'
import YearlyComparetableChart from './ComparetableCharts/Year'
import DataSheet from './DataSheet'
import MonthReportChart from './ReportCharts/Month'
import QuarterReportChart from './ReportCharts/Quarter'
import YearReportChart from './ReportCharts/Year'
import TimeRanges from './TimeRanges'

const today = dayjs()
const BusinessPerformance = () => {

    const [state, setState] = useState({
        gte: today?.startOf('year'),
        lte: today?.endOf('year'),
        layout: 'chart', // chart | datagrid
        mode: 'year', // year | quarter | month
        comparetable: '', // comparetable | ''
        chartMode: { year: undefined, month: undefined }
    })

    const { data: { monthly } } = ReportApi.BusinessPerformance({
        query: {
            gte: format_date_short(state?.gte?.$d || state?.gte),
            lte: format_date_short(state?.lte?.$d || state?.lte),
        }
    })

    const renderComponent = () => {
        const code = state?.layout + "+" + state?.mode + "+" + state?.comparetable
        switch (code) {
            case 'chart+year+comparetable': return <YearlyComparetableChart datasheets={monthly} />
            case 'chart+quarter+comparetable': return <QuarterComparetableChart datasheets={monthly} />
            case 'chart+month+comparetable': return <MonthComparetableChart datasheets={monthly} />
            case 'chart+year+': return <YearReportChart datasheets={monthly} />
            case 'chart+quarter+': return <QuarterReportChart datasheets={monthly} />
            case 'chart+month+': return <MonthReportChart datasheets={monthly} />
            default: return <DataSheet datasheets={monthly} />
        }
    }

    return (
        <>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                <TimeRanges state={state} setState={setState} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: { xs: 'column', md: 'row' }, gap: 1.5 }}>
                    <Mode state={state} setState={setState} />
                    <Create state={state} setState={setState} />
                    <Import state={state} setState={setState} />
                    <BusinessPerformanceSampleFile state={state} setState={setState} />
                    <Export state={state} setState={setState} />
                </Box>
            </Box>
            {renderComponent()}
        </>
    )
}

export default withSuspense(BusinessPerformance, MuiSkeleton["LoadingDotBox"])
