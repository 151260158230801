import { format_numeral_price } from '@/functions';
import ItemPaperStatistical from '@components/shared/ItemPaperStatistical';
import { Grid } from '@mui/material';

const Summary = ({ invoices = [] }) => {
    const formatToArray = () => {
        return [
            {
                label: "Tổng Doanh Thu/ Doanh Số", value:
                    format_numeral_price(
                        invoices.reduce((total, invoice) => {
                            return total + invoice?.total
                        }, 0))
            },
        ];
    };
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {Array.from(formatToArray()).map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <ItemPaperStatistical {...item} />
                </Grid>
            ))}
        </Grid>
    );
}

export default Summary