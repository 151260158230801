import Invoice from "./Invoice";
import Service from "./Service";
import Industry from "./Industry";
import BusinessPerformance from "./BusinessPerformance";
import Balance from "./Balance";
import Layout from "./Layout";
import Profitability from "./Profitability";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Invoice,
    Service,
    Industry,
    Layout,
    BusinessPerformance,
    Balance,
    Profitability,
    Effeciency: () => { return <></> },
    Operation: () => { return <></> },
}