import ReportApi from "@/apis/beta/Report/ReportApi";
import { downloadFileWithBinaryResponse } from "@/functions";
import withPermission from "@/hocs/withPermission";
import ButtonBaseExport from "@components/DataGrid/Filter/ButtonExports/shared/ButtonBaseCustom";
import { useMutation } from "react-query";

const FinanceInvoiceExport = ({ query }) => {
    const { mutateAsync, isLoading } = useMutation(ReportApi.InvoiceExport);

    const handler = async () => {
        const response = await mutateAsync({ query });
        if (!response) return;
        downloadFileWithBinaryResponse(`${Date.now()}.xlsx`, response);
    };

    return (
        <ButtonBaseExport
            styled={{
                "&.MuiButton-root": {
                    height: 40,
                    m: "auto 0",
                },
            }}
            handler={handler}
            loading={isLoading}
            disabled={isLoading}
        />
    );
};

export default withPermission(FinanceInvoiceExport, { feature: 'report', action: 'export-finance-invoices', type: 'Button' });
