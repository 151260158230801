import DataGridCustomize from '@components/DataGrid'
import CellPricing from '@components/DataGrid/Cell/FormatterPricing'
import DataCell from '../../DataCell'

const DataGrid = ({
    mode = { timeline: 'monthly' },
    setMode = () => { },
    data = {
        invoices: [],
        services: [],
        industries: []
    } }) => {

    const columnGroupingModel = [
        {
            groupId: 'internal_data',
            headerName: 'Quý I',
            description: '',
            children: [{ field: 'm1' }, { field: 'm2' }, { field: 'm3' }],
        },
        {
            groupId: 'internal_data_2',
            headerName: 'Quý II',
            description: '',
            children: [{ field: 'm4' }, { field: 'm5' }, { field: 'm6' }],
        },
        {
            groupId: 'internal_data_3',
            headerName: 'Quý III',
            description: '',
            children: [{ field: 'm7' }, { field: 'm8' }, { field: 'm9' }],
        }, {
            groupId: 'internal_data_4',
            headerName: 'Quý IV',
            description: '',
            children: [{ field: 'm10' }, { field: 'm11' }, { field: 'm12' }],
        }
    ]
    return (
        <DataGridCustomize
            rows={data?.services?.map((service, index) => {
                let name = service
                if (!service) { name = 'KHÁC' }
                let months = {}
                let mCollections = []
                let qCollections = []
                for (let i = 1; i <= 12; i++) {
                    months[`m${i}`] = data?.invoices.reduce((total, invoice) => {
                        if (invoice?.service_group_code === service && invoice?.month === i) {
                            mCollections.push(invoice)
                            return total + invoice?.total
                        }
                        return total
                    }, 0)
                    months[`m${i}_collections`] = mCollections
                }
                //
                let quarters = {}
                for (let i = 1; i <= 4; i++) {
                    quarters[`q${i}`] = data?.invoices.reduce((total, invoice) => {
                        if (invoice?.service_group_code === service && Math.ceil(invoice?.month / 3) === i) {
                            qCollections.push(invoice)
                            return total + invoice?.total
                        }
                        return total
                    }, 0)
                    quarters[`q${i}_collections`] = qCollections
                }


                return {
                    id: index + 1,
                    name,
                    ...months,
                    ...quarters,
                }
            })}
            columns={[
                {
                    field: 'name', headerName: 'Nhóm Sản Phẩm', minWidth: 180,
                },
                ...Array.from({ length: 12 })?.map((__, index) => index + 1)?.map((month) => {
                    return {
                        field: `m${month}`,
                        headerName: `Tháng ${month}`,
                        width: 120,
                        hide: mode?.timeline !== 'monthly',
                        renderCell: ({ value, row }) => <DataCell
                            month={month}
                            {...row} invoices={[...row?.[`m${month}_collections`]]} price={value} />
                    }
                }),
                ...Array.from({ length: 4 })?.map((__, index) => index + 1)?.map((month) => {
                    return {
                        field: `q${month}`,
                        headerName: `Quý ${month}`,
                        hide: mode?.timeline !== 'quarterly',
                        width: 120,
                        renderCell: ({ value }) => <CellPricing price={value} />
                    }
                }),

            ]}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            initialState={{ pinnedColumns: { left: ['name'] } }}
            components={{
                Toolbar: 'disabled'
            }}
        />
    )
}

export default DataGrid