import { payloadToQueries, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class BalanceSheetApi {
    Create = async ({ data = {} }) => {
        await requestWithToken(
            `${API_V3_URL}/reports/finance/balance_sheets`,
            "POST",
            {
                balance_sheet: data
            },
        ).then(({ data }) => {
            return data;
        }).catch((err) => {
            return err
        })
    };

    List = ({ query }) => {
        const queries = payloadToQueries(query);
        return useQueryWithCustomized(
            ["reports.finance.balance.sheets", queries],
            async () => {
                return await requestWithToken(
                    `${API_V3_URL}/reports/finance/balance_sheets?${queries}`
                )
                    .then(({ data }) => {
                        if (data?.errors) return data;

                        return data?.data?.balance_sheets || []
                    })
                    .catch((error) => {
                        return []
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    };

    Export = async ({ query }) => {
        const queries = payloadToQueries(query);
        await requestWithToken(
            `${API_V3_URL}/reports/finance/balance_sheets?${queries}`,
            "GET",
        ).then(({ data }) => {
            return data;
        }).catch((err) => {
            return err
        })
    };
}

export default BalanceSheetApi = new BalanceSheetApi();
