import ReportApi from '@/apis/beta/Report/ReportApi'
import { format_date_short } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import MuiScrollTabs from '@components/shared/MuiScrollTabs'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import Filter from './Filter'
import FinanceInvoice from './Table'
import Summary from './Sumary'

const Invoice = () => {
    const today = dayjs()
    const [state, setState] = useState({
        gte: today.startOf('year'),
        lte: today.endOf('year'),
        q: '',
        services: '',
        industries: '',
        employees: ''
    })
    const { data, isFetching } = ReportApi.Finance({
        query: {
            ...state,
            gte: format_date_short(state?.gte?.$d),
            lte: format_date_short(state?.lte?.$d),
            response_format: 'json'
        }
    })
    return (
        <>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, fontWeight: 'medium', mb: 2 }}>
                Báo Cáo Tài Chính
            </Typography>
            <MuiScrollTabs
                prefix='reports/finance'
                initialTabs={[
                    {
                        label: 'theo hoá đơn',
                        href: 'invoices',
                        value: 'invoices'
                    },
                    {
                        label: 'theo sản phẩm/ dịch vụ',
                        href: 'services',
                        value: 'services'
                    },
                    {
                        label: 'theo lĩnh vực',
                        href: '?view=services',
                        value: 'a'
                    }
                ]} />
            <Filter
                services={data?.services}
                industries={data?.industries}
                state={state} setState={setState} />
            <Summary invoices={data?.invoices} />
            <FinanceInvoice isFetching={isFetching} data={data?.invoices} />
        </>
    )
}

export default withSuspense(Invoice, MuiSkeleton["DataGrid"])