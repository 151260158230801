import BalanceSheetApi from '@/apis/beta/Report/BalanceSheetApi'
import ProfitabilityApi from '@/apis/beta/Report/ProfitabilityApi'
import NumericFormatCustom from '@components/NumericFormatCustom'
import FormCustomized from '@components/shared/FormCustomized'
import { useNotificationContext } from '@contexts/notification'
import { AddOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

const today = dayjs()
const Create = () => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()

    const [created, setCreated] = useState(false)
    const methods = useForm({
        defaultValues: { ebit: 0, month: today.get('month'), year: today.get('year') }
    })
    const { mutateAsync, isLoading } = useMutation(ProfitabilityApi.Create)

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            data: values
        })

        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message || 'Failed', severity: 'error' })
        }
        queryClient.refetchQueries('reports.finance.profitabilities.sheets')

        return setNotification({ open: true, message: 'Tạo thành công', severity: 'success' })
    }

    const BALANCE_SHEET_FIELDS = [
        {
            name: 'year', component: "SingleSelect", cols: { xs: 12, md: 6, xl: 6 },
            params: {
                options: [...Array.from({ length: 100 }).map((__, i) => i + 2000)?.map((value) => ({ value, label: value }))],
                input: { label: 'Năm tạo báo cáo', placeholder: 'Chọn năm báo cáo' },
            }
        }, {
            name: 'month', component: "SingleSelect", cols: { xs: 12, md: 6, xl: 6 },
            params: {
                options: [...Array.from({ length: 12 }).map((__, i) => i + 1)?.map((value) => ({ value, label: value }))],
                input: { label: 'Tháng tạo báo cáo', placeholder: 'Chọn tháng báo cáo' },
            }
        }, {
            name: 'ebit', component: "TextField", cols: { xs: 12, md: 12, xl: 12 },
            params: {
                label: 'EBIT *', placeholder: 'Nhập giá trị ebit(Earnings before interest & taxes)...',
                InputProps: { inputComponent: NumericFormatCustom }
            }
        }
    ]
    return (
        <>
            <Button size='small' variant='outlined' startIcon={<AddOutlined />}
                onClick={() => setCreated(true)}
                disabled={created}
            >
                Tạo Báo Cáo
            </Button>
            <FormProvider {...methods}>
                <Dialog open={created} fullWidth maxWidth="md" component="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle>
                        Tạo Báo Cáo [Khả Năng Sinh Lợi]
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <FormCustomized attributes={BALANCE_SHEET_FIELDS} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button size='small'
                            disabled={isLoading}
                            variant='outlined'
                            onClick={() => setCreated(false)}
                        >Huỷ Thao Tác</Button>
                        <Button size='small'
                            disabled={isLoading}
                            startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                            variant='contained' type="submit">Tạo Báo Cáo</Button>
                    </DialogActions>
                </Dialog>
            </FormProvider>
        </>

    )
}

export default Create