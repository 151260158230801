import { format_numeral_price } from '@/functions'
import DataGridCustomize from '@components/DataGrid'
import { CellLink, CellPricing } from '@components/DataGrid/Cell'
import { Box, Paper, Popover, Typography } from '@mui/material'
import { useState } from 'react'

const DataCell = ({ invoices = [], price = 0, month, ...params }) => {

    const [viewable, setView] = useState(undefined)
    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', p: 'auto 8px', alignItems: 'center' }}>
            <Typography
                onClick={(event) => setView(event?.currentTarget)}
                component="div" sx={{
                    fontSize: 14,
                    m: 'auto 0 auto auto',
                    width: '100%',
                    textAlign: 'right',
                    cursor: 'pointer',
                }}>
                {format_numeral_price(price)}
            </Typography>
            {!!viewable && <Popover open={true} anchorEl={viewable} onClose={() => setView(undefined)}>
                <Paper sx={{ p: 2, minWidth: { xs: '90%', md: 500, xl: 700, lg: 900 }, maxHeight: { xs: '100%', md: 400, xl: 500, lg: 600 }, overflow: 'auto' }} elevation={10}>
                    <Typography sx={{ fontSize: 12, mb: 1.25, fontWeight: 'medium' }}>
                        Bảng
                    </Typography>
                    <DataGridCustomize
                        components={{
                            Toolbar: 'disabled',
                            Pagination: 'disabled',
                        }}
                        columns={[
                            {
                                field: 'invoice_id', valueGetter: ({ row }) => {
                                    return {
                                        label: row?.invoice_id,
                                        href: ['invoices', row?.invoice_id],
                                    }
                                }, headerName: 'Số DC', minWidth: 100, renderCell: ({ value }) => <CellLink data={value} />
                            },
                            {
                                field: 'customer', valueGetter: ({ row }) => {
                                    return {
                                        label: row?.company_name || row?.customer_name,
                                        href: ['customers', row?.customer_id],
                                    }
                                }, headerName: 'Khách Hàng', minWidth: 250, renderCell: ({ value }) => <CellLink data={value} />
                            },
                            { field: 'invoice_detail', headerName: 'Chi Tiết', minWidth: 250 },
                            { field: 'total', headerName: 'Thành Tiền/ Doanh Số', width: 160, renderCell: ({ value }) => <CellPricing price={value} />, sortable: true },
                        ]}
                        paginationMode='client'
                        rows={invoices?.filter(invoice => invoice?.service_group_code === params?.name && invoice?.total !== 0 && invoice?.month === month)?.map((invoice, index) => ({ ...invoice, id: index }))}
                    />
                </Paper>
            </Popover>}
        </Box>
    )
}

export default DataCell