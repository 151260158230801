import BalanceSheetApi from '@/apis/beta/Report/BalanceSheetApi'
import { format_date_short } from '@/functions'
import { SCHEMES } from '@components/EChart'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, FormControl, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useState } from 'react'
import BarChart from '../shared/Charts/Comparetable/BarChart'
import BalanceHeader from './Header'


const BalanceCategories = [
    { value: 'assets', label: 'Tài Sản', code: 'total_assets' },
    { value: 'liabilities', label: 'Tổng Nợ', code: 'total_liabilities' },
    { value: 'equity', label: 'Nguồn VCSH', code: 'total_equity' },
]

const today = dayjs()
const FinanceBalance = () => {
    const [queries, useQueries] = useState({
        gte: today?.startOf('year'),
        lte: today?.endOf('year'),
        charts: {
            mode: 'month',
            current: { year: today?.get('year'), quarter: undefined, month: undefined },
            compare: { year: undefined, quarter: undefined, month: undefined, enabled: false }
        }
    })
    const { data: datasheets = [], isLoading } = BalanceSheetApi.List({
        query: {
            gte: format_date_short(queries?.gte?.$d || queries?.gte),
            lte: format_date_short(queries?.lte?.$d || queries?.lte),
            response_format: 'json'
        }
    })
    return (
        <>
            <BalanceHeader datasheets={datasheets} queries={queries} callbackQueries={useQueries} />
            {isLoading && <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', position: 'absolute', width: '100%', top: '16px', pt: 3, justifyContent: 'center' }}>
                    <Typography sx={{ textAlign: 'center' }}>Hệ thống đang tính toán dữ liệu, vui lòng đợi...</Typography>
                    <MuiSkeleton.LinearProgress />
                </Box>
            </Box>}
            <Grid container sx={{ mt: 2, opacity: isLoading ? '0.1' : 1 }} >
                <Grid item xs={12} md={12}>
                    <BalanceBarChart datasheets={datasheets} queries={queries} updateQueries={useQueries} />
                </Grid>
            </Grid>
        </>
    )
}

const BalanceBarChart = ({
    datasheets = [],
    queries = {},
    updateQueries = () => { }
}) => {
    const options = [
        { label: 'Tháng', value: 'month' },
        { label: 'Quý', value: 'quarter' },
        { label: 'Năm', value: 'year' },
    ]

    const payloads = buildBalanceSheetBars({ datasheets, queries })

    const [selecteds, setSelecteds] = useState(payloads?.legends?.map(x => x?.value))
    return (
        <BarChart title={{
            text: 'Balance Sheet',
        }}
            objectKey={'total_assets'}
            {...payloads}
            selectedLegends={selecteds}
            setSelecteds={setSelecteds}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 14, minWidth: 80 }}>
                    Xem theo{":"}
                </Typography>
                <FormControl fullWidth sx={{ minWidth: 120 }}>
                    <SingleSelectCustomize
                        options={options}
                        value={options?.find(option => option.value === queries?.charts?.mode)}
                        onChange={(_, newValue) => {
                            updateQueries({
                                ...queries,
                                charts: {
                                    ...queries?.charts,
                                    mode: newValue?.value
                                }
                            })
                        }}
                    />
                </FormControl>
            </Box>
        </BarChart>
    )
}

const quarters = ["I", "II", "III", "IV"]
const buildBalanceSheetBars = ({
    datasheets = [],
    queries = { charts: { mode: '', current: {}, compare: {} } }
}) => {
    // defined variables
    let labels = []
    let series = []

    // uniq years on mode viewers
    const years = _.uniq(datasheets?.map((datasheet) => datasheet?.year))?.sort((a, b) => a - b)

    switch (queries?.charts?.mode) {
        case 'month':
            // completed labels
            years?.forEach((year) => {
                Array.from({ length: 12 })?.forEach((__, index) => {
                    labels.push({
                        year: year,
                        quarter: Math.ceil((index + 1) / 3),
                        month: index + 1,
                        label: `M${index + 1} ${years.length === 1 ? '' : `-Y${year}`}`
                    })
                })
            })
            // build series/ datasource for year
            BalanceCategories?.forEach((balanceCategory, index) => {
                const dataset = labels?.map((label) => {
                    return _.sumBy(datasheets, (datasheet) => {
                        if (datasheet?.year === label?.year && datasheet?.month === label?.month) {
                            return datasheet?.[`${balanceCategory?.code}`]
                        }
                        return 0
                    })
                })

                series.push({
                    name: balanceCategory?.label,
                    value: balanceCategory?.value,
                    max: _.max(dataset),
                    data: dataset,
                    itemStyle: { color: SCHEMES.default[index] }
                })
            })
            break;
        case 'quarter':
            years?.forEach((year) => {
                Array.from({ length: 4 })?.forEach((__, index) => {
                    labels.push({
                        year: year,
                        quarter: index + 1,
                        month: index + 1,
                        label: `Q${quarters[index]} ${years.length === 1 ? '' : `-Y${year}`}`
                    })
                })
            })
            // build series/ datasource for year
            BalanceCategories?.forEach((balanceCategory, index) => {
                const dataset = labels?.map((label) => {
                    return _.sumBy(datasheets, (datasheet) => {
                        if (datasheet?.year === label?.year && Math.ceil(datasheet?.month / 3) === label?.quarter) {
                            return datasheet?.[`${balanceCategory?.code}`]
                        }
                        return 0
                    })
                })
                series.push({
                    name: balanceCategory?.label,
                    value: balanceCategory?.value,
                    max: _.max(dataset),
                    data: dataset,
                    itemStyle: { color: SCHEMES.default[index] }
                })
            })
            break;
        case 'year':
            years?.forEach((year) => {
                labels.push({
                    year: year,
                    label: `Y${year}`
                })
            })
            // build series/ datasource for year
            BalanceCategories?.forEach((balanceCategory, index) => {
                const dataset = labels?.map((label) => {
                    return _.sumBy(datasheets, (datasheet) => {
                        if (datasheet?.year === label?.year && datasheet?.year === label?.year) {
                            return datasheet?.[`${balanceCategory?.code}`]
                        }
                        return 0
                    })
                })
                series.push({
                    name: balanceCategory?.label,
                    value: balanceCategory?.value,
                    max: _.max(dataset),
                    data: dataset,
                    itemStyle: { color: SCHEMES.default[index] }
                })
            })
            break;
        default:
            break;
    }

    return {
        series: series,
        legends: BalanceCategories,
        labels: labels?.map((label) => label?.label),
        datasheets
    }
}

export default FinanceBalance