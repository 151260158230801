import BalanceTimes from './BalanceTimes'

const BalanceHeader = ({
    callbackQueries = () => { },
    queries = {},
}) => {
    return (
        <>
            {/* <Statistic datasheets={datasheets} /> */}
            <BalanceTimes queries={queries} callbackQueries={callbackQueries} />
        </>
    )
}

export default BalanceHeader