import { MultipleStopOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

const Mode = ({ state, setState }) => {
    return (
        <Button size='small' startIcon={<MultipleStopOutlined fontSize='small' />} variant='outlined'
            onClick={() => setState(preState => ({
                ...preState,
                layout: preState?.layout === 'datagrid' ? 'chart' : 'datagrid'
            }))}
        >
            {state?.layout === 'datagrid' ? 'Xem Thống Kê' : 'Xem Dữ Liệu'}
        </Button>
    )
}

export default Mode